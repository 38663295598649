import axios from "axios";

const Helpers = {};

Helpers.config = {
  folder_path: process.env.REACT_APP_FOLDER_PATH,
  isProduction: process.env.NODE_ENV === "production",
  api: {
    url: process.env.REACT_APP_API_URL,
    resources: process.env.REACT_APP_RESOURCES_URL,
  },
  company: {
    name:
      localStorage.getItem("theme") === "2"
        ? "Telcotech"
        : process.env.REACT_APP_COMPANY_NAME,
  },
  convertUrl: ({ url }) => Helpers.config.folder_path + url,
  resourcesUrl: ({ url }) => Helpers.config.api.resources + url,
};

Helpers.axios = ({ method = "GET", url = "", data = {}, hasFile = false }) => {
  let urlReq =
    method === "GET" ? url + "?jwt=" + Helpers.localStorage.getJWT() : url;
  let bodyReq;
  if (method === "POST") {
    if (hasFile) {
      bodyReq = data;
      bodyReq.append("jwt", Helpers.localStorage.getJWT());
    } else {
      bodyReq = { ...data };
      bodyReq.jwt = Helpers.localStorage.getJWT();
    }
  }
  let config = {
    method,
    url: Helpers.config.api.url + urlReq,
    data: bodyReq,
  };

  if (hasFile) {
    config.headers = { "content-type": "multipart/form-data" };
  }

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        return res.data;
      })
      .then((res) => {
        /* if (!Helpers.config.isProduction) {
        console.log(res)
      } */
        if (res.message === "error_session_api") {
          let theme = parseInt(localStorage?.theme);
          localStorage.clear();
          window.location.href =
            theme === 2
              ? Helpers.config.convertUrl({ url: "/login2" })
              : Helpers.config.folder_path + "/";
          return;
        }
        if (res.hasOwnProperty("data")) {
          resolve(res);
        } else {
          reject({
            response: "danger",
            message:
              "No se ha podido realizar la conexión al servidor. Contacte con el área de IT.",
          });
        }
      })
      .catch((req) => {
        /* if (!Helpers.config.isProduction) {
        console.log(req)
      } */
        reject({
          response: "danger",
          message:
            "No se ha podido realizar la conexión al servidor. Contacte con el área de IT.",
        });
      });
  });
};

Helpers.promise = {};
Helpers.promise.catch = ({ req = {}, toast = {} }) => {
  /* if (!Helpers.config.isProduction) {
    console.log(req)
  } */
  if (req.response) {
    Helpers.toast.construct({
      response: req.response,
      message: req.message,
      toast,
    });
  } else {
    Helpers.toast.construct({
      response: "error",
      message:
        "No se ha podido realizar la conexión al servidor. Contacte con el área de IT.",
      toast,
    });
  }
};

Helpers.toast = {
  options: () => {
    return {
      autoClose: 6000,
      hideProgressBar: false,
      pauseOnHover: true,
      progress: 0.2,
    };
  },
  construct: ({ response = "", message = "", toast = {} }) => {
    if (response === "success") {
      toast.success(message, "", Helpers.toast.options());
    } else if (response === "info") {
      toast.info(message, "", Helpers.toast.options());
    } else if (response === "warning") {
      toast.warning(message, "", Helpers.toast.options());
    } else {
      toast.error(message, "", Helpers.toast.options());
    }
  },
};

Helpers.localStorage = {
  name: "session_react",
  getSession: () => JSON.parse(localStorage.getItem(Helpers.localStorage.name)),
  exists: () => localStorage.getItem(Helpers.localStorage.name),
  existsJWT: () => {
    if (Helpers.localStorage.exists()) {
      if (Helpers.localStorage.getSession().jwt) {
        return true;
      }
    }
    return false;
  },
  setSession: (session) =>
    localStorage.setItem(Helpers.localStorage.name, JSON.stringify(session)),
  getJWT: () =>
    Helpers.localStorage.existsJWT()
      ? Helpers.localStorage.getSession().jwt
      : "",
};

Helpers.date = {
  get: ({ format = "%Y-%m-%d", addDays = 0 } = {}) => {
    let date = new Date();
    if (addDays !== 0) {
      date.setDate(date.getDate() + addDays);
    }

    let d = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
      hours: date.getHours(),
      minutes: date.getMinutes(),
    };
    let dateTex = format
      .replace("%Y", d.year)
      .replace("%m", (d.month + 1 < 10 ? "0" : "") + (d.month + 1))
      .replace("%d", (d.day < 10 ? "0" : "") + d.day)
      .replace("%H", (d.hours < 10 ? "0" : "") + d.hours)
      .replace("%i", (d.minutes < 10 ? "0" : "") + d.minutes)
      .replace("%a", d.hours < 12 ? "AM" : "PM");

    return dateTex;
  },
};

Helpers.number = {
  float: {
    format: ({ number } = {}) => {
      return parseFloat(number).toLocaleString("es-MX", {
        minimumFractionDigits: 2,
      });
    },
  },
};

Helpers.string = {
  toCamelCase: (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
};

export default Helpers;
