import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import { Link, useParams } from 'react-router-dom';
import { FormCustom, Input, Select } from '../../Components/Form/Form';
import ReactPlaceholder from 'react-placeholder/lib';
import { useSelector } from 'react-redux';
import { PieChart } from 'react-minimal-pie-chart';

const CommentHistory = ({ comments }) => {

    console.log(comments)
    return <>
        <div className="form-row">
            {comments.length > 0 ? <>
                <div className="col-12">
                    {React.Children.toArray(comments.map(f => <div>
                        <div style={{
                            borderRadius: 10,
                            backgroundColor: '#F0F2F5'
                        }} className="d-flex justify-content-between p-2 mb-2">
                            <div className="d-flex flex-column">
                                <div>
                                    {/* <a href={'https://www.facebook.com/' + f.from_id} className="align-self-start" target="_blank">
                                        <strong>{f.from_name}</strong>
                                    </a> */}
                                    <strong className="text-primary">{f.from_name}</strong>
                                    <small className="text-muted"> - {f.date_created}</small>
                                </div>
                                <span>{f.message}</span>
                                {f.media ? <img src={f.media} className="img-fluid" /> : ''}
                            </div>
                            <div>
                                <a href={f.permalink_url} className="align-self-start" target="_blank">
                                    <i className="fa fa-link"></i>
                                </a>
                            </div>
                        </div>
                        <div style={{
                            borderRadius: 10,
                            backgroundColor: '#F0F2F5'
                        }} className="d-flex flex-column p-2 ml-4 mb-4">
                            <strong>{parseInt(f.is_cm) === 0 ? 'Respuesta ChatBot' : 'Derivado al CM'}</strong>
                            {parseInt(f.is_cm) === 0 ? <>
                                <span>{f.response_bot}</span>
                                {f.response_image_bot ? <img src={f.response_image_bot} className="img-thumbnail mt-2" width="250" /> : ''}
                            </> : ''}
                        </div>
                    </div>))}
                </div>
            </> : <div className="col-12">
                <div className="alert alert-warning">
                    No hay comentarios
                </div>
            </div>}
        </div>
    </>
}

export default CommentHistory