import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { TextRow } from 'react-placeholder/lib/placeholders'
import Controllers from '../../Api/Controllers';
import {Select} from '../Form/Form'

export default function AgentSelection(props) {
  const [load, setLoad] = useState(false);
  const [value, setValues] = useState('')
  
  const [listAgentes, setListAgentes] = useState(false);
  
  const {
    agents,
    onClickAdd,
    deleteAgent,
  } = props;
  
  useEffect(() => {
    setLoad(true);
    Controllers.customers_contact.get_customers_contact()
      .then((res) => setListAgentes(res.data))
      .finally(() => setLoad(false))
  }, []);


  return (
    <div className="w-100">
      { load ? (
        <TextRow style={ { height: 30, borderRadius: 5 } } color="#E0E0E0" />
      ) : listAgentes.length > 0 && <Select
        options={ listAgentes.map((agt) => {
          return {
            label: agt.name_contact,
            value: agt.id_customers_contact,
            isDisabled: agents.some((a) => {
              return (
                parseInt(a.id) ===
                parseInt(agt.id_customers_contact)
              );
            }),
          };
        }) }
        onChange={ (e) => setValues({
          name: e.label,
          id: e.value,
        }) }
        defaultValue={ value }
        classNameParent="w-100 mb-2"
        text="Agentes"
        append={ [
          <Button
            variant="primary"
            onClick={ () => {
              if (value) {
                onClickAdd(value)
                setValues('')
              }
            } }
          >
            <i className="fa fa-plus"></i>
          </Button>,
        ] }
      /> }
      
      <strong className="text-primary">
        Agentes seleccionados
      </strong>
      {React.Children.toArray(
        agents.map((agent, j) => {
          return (
            <div className="flex-row d-flex justify-content-between p-2 align-items-center">
              <strong>
                #{j + 1}. {agent.name}
              </strong>
              <Button
                variant="danger"
                size="sm"
                onClick={(_) => {
                  deleteAgent(agent.id);
                }}
              >
                <i className="fa fa-trash"></i>
              </Button>
            </div>
          );
        })
      )}
    </div>
  )
}
