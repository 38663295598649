import styled from 'styled-components'
import { widthResize, widthFull, heightHeader, primaryGradient, boxShadow } from '../Resources/Theme'

const Header = styled.header`
  position: fixed;
  height: ${heightHeader};
  width: calc(100% - ${props => props.resize ? widthResize : widthFull});
  left: ${props => props.resize ? widthResize : widthFull};
  background: #fff;
  transition: .5s;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 4;
  border-bottom: 2px solid #CBCBCE;
  
  @media (max-width: 992px) {
    left: 0;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

Header.Left = styled.div`
  height: ${heightHeader};
  display: none;
  align-center;
  color: #fff;

  @media (max-width: 992px) {
    width: 33%;
    display: flex;
  }
`

Header.Brand = styled.div`
  display: none;
  max-height: ${heightHeader};

  @media (max-width: 992px) {
    align-self: center;
    justify-content: center;
    display: flex;
  }
`

Header.Brand.Logo = styled.img`
  @media (max-width: 992px) {
    max-height: 40px;
  }
`

Header.Right = styled.div`
  height: ${heightHeader};
  display: flex;
  justify-content: flex-end;
  max-heigth: ${heightHeader};

  @media (max-width: 992px) {
    width: 100%;
  }
`

Header.Right.Icon = styled.div`
  height: ${heightHeader};
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: rgba(0,0,0,.1);
  }

  @media (max-width: 992px) {
    width: 40px;
  }
`

Header.Right.Image = styled.img`
  border-radius: ${props => props.maxHeight ? props.maxHeight : '30px'};
  max-height: ${props => props.maxHeight ? props.maxHeight : '30px'};
  box-shadow: 0 1px 5px 0 rgba(0,0,0,.2);
`

export default Header