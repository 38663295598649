import React from "react";

function EditService({ service, setService, editService }) {
  return (
    <>
      <div className="card">
        <div className="card-body">
          <h3 className="font-weight-bold text-center">Editar servicio</h3>
          <form onSubmit={(e) => editService(e)}>
            <div className="form-group">
              <label htmlFor="name">Nombre</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Nombre del servicio"
                value={service.service}
                onChange={(e) =>
                  setService({ ...service, service: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="keywords">Keywords</label>
              <input
                type="text"
                className="form-control"
                id="keywords"
                placeholder="Keywords del servicio"
                value={service.keywords}
                onChange={(e) => setService({...service, keywords: e.target.value})}
              />
            </div>
            <input
              name="add"
              id="add"
              class="btn btn-success btn-block"
              type="submit"
              value="Agregar"
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default EditService;
