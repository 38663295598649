import Helpers from '../Components/Helpers/Helpers';

const Controllers = {
  users: {
    get_login: (data = {}) => Helpers.axios({ url: '/users/login', method: 'POST', data }),
    get_login_telcotech: (data = {}) => Helpers.axios({ url: '/users/login/telcotech', method: 'POST', data }),
    get_users: () => Helpers.axios({ url: '/users/get/all' }),
    get_users_id: ({ p_id_user }) => Helpers.axios({ url: '/users/get/id/' + p_id_user }),
    users_insert_update: (data = {}) => Helpers.axios({ url: '/users/insert/update', method: 'POST', data }),
    users_delete: (data = {}) => Helpers.axios({ url: '/users/delete', method: 'POST', data })
  },
  emails: {
    get_emails: () => Helpers.axios({ url: '/emails/get/all' }),
  },
  customers: {
    get_customers: () => Helpers.axios({ url: '/customers/get/all' }),
    get_customers_public: () => Helpers.axios({ url: '/customers/get/public' }),
    get_customers_id: ({ p_id_customers }) => Helpers.axios({ url: '/customers/get/id/' + p_id_customers }),
    customers_insert_update: (data = {}) => Helpers.axios({ url: '/customers/insert/update', method: 'POST', data }),
    customers_delete: (data = {}) => Helpers.axios({ url: '/customers/delete', method: 'POST', data }),
    tags_items_customers_insert_update: (data = {}) => Helpers.axios({ url: '/customers/tagsitems/insert/update', method: 'POST', data })
  },
  customers_contact: {
    get_customers_contact: () => Helpers.axios({ url: '/customers_contact/get/all' }),
    get_customers_contact_id: ({ p_id_customers_contact }) => Helpers.axios({ url: '/customers_contact/get/id/' + p_id_customers_contact }),
    customers_contact_insert_update: (data = {}) => Helpers.axios({ url: '/customers_contact/insert/update', method: 'POST', data }),
    customers_contact_delete: (data = {}) => Helpers.axios({ url: '/customers_contact/delete', method: 'POST', data })
  },
  project: {
    get_project: () => Helpers.axios({ url: '/project/get/all' }),
    get_project_public: () => Helpers.axios({ url: '/project/get/public' }),
    get_project_customers: () => Helpers.axios({ url: '/project/customers/get/public' }),
    get_project_id: ({ p_id_project }) => Helpers.axios({ url: '/project/get/id/' + p_id_project }),
    project_insert_update: (data = {}) => Helpers.axios({ url: '/project/insert/update', method: 'POST', data }),
    project_delete: (data = {}) => Helpers.axios({ url: '/project/delete', method: 'POST', data })
  },
  area: {
    get_area: ({ p_id_project }) => Helpers.axios({ url: '/area/get/all/' + p_id_project }),
    get_area_id: ({ p_id_area }) => Helpers.axios({ url: '/area/get/id/' + p_id_area }),
    area_insert_update: (data = {}) => Helpers.axios({ url: '/area/insert/update', method: 'POST', data }),
    area_delete: (data = {}) => Helpers.axios({ url: '/area/delete', method: 'POST', data })
  },
  event: {
    get_event: ({ p_id_project }) => Helpers.axios({ url: '/event/get/all/' + p_id_project }),
    get_event_id: ({ p_id_event }) => Helpers.axios({ url: '/event/get/id/' + p_id_event }),
    event_insert_update: (data = {}) => Helpers.axios({ url: '/event/insert/update', method: 'POST', data }),
    event_delete: (data = {}) => Helpers.axios({ url: '/event/delete', method: 'POST', data })
  },
  footage: {
    get_footage: ({ p_id_project }) => Helpers.axios({ url: '/footage/get/all/' + p_id_project }),
    get_footage_id: ({ p_id_footage }) => Helpers.axios({ url: '/footage/get/id/' + p_id_footage }),
    footage_insert_update: (data = {}) => Helpers.axios({ url: '/footage/insert/update', method: 'POST', data }),
    footage_delete: (data = {}) => Helpers.axios({ url: '/footage/delete', method: 'POST', data })
  },
  keyword: {
    get_keyword: ({ p_id_project }) => Helpers.axios({ url: '/keyword/get/all/' + p_id_project }),
    get_keyword_id: ({ p_id_keyword }) => Helpers.axios({ url: '/keyword/get/id/' + p_id_keyword }),
    keyword_insert_update: (data = {}) => Helpers.axios({ url: '/keyword/insert/update', method: 'POST', data }),
    keyword_delete: (data = {}) => Helpers.axios({ url: '/keyword/delete', method: 'POST', data })
  },
  seller: {
    get_seller: () => Helpers.axios({ url: '/seller/get/all' }),
    get_seller_id: ({ p_id_seller }) => Helpers.axios({ url: '/seller/get/id/' + p_id_seller }),
    seller_insert_update: (data = {}) => Helpers.axios({ url: '/seller/insert/update', method: 'POST', data }),
    seller_delete: (data = {}) => Helpers.axios({ url: '/seller/delete', method: 'POST', data })
  },
  lead: {
    get_lead: (data) => Helpers.axios({ url: '/lead/get/all', method: 'POST', data }),
    get_lead_option: (data) => Helpers.axios({ url: '/lead/option/get/all', method: 'POST', data }),
    get_lead_multiagente: (data) => Helpers.axios({ url: '/lead/multiagente/get/all', method: 'POST', data }),
    get_lead_multiagente_excel: (data) => Helpers.axios({ url: '/lead/multiagente/excel/get/all', method: 'POST', data }),
    get_lead_percentage: ({ p_date_start, p_date_end }) => Helpers.axios({ url: '/lead/get/percentage/' + p_date_start + '/' + p_date_end }),
    get_lead_customers: ({ p_date_start, p_date_end }) => Helpers.axios({ url: '/lead/get/customers/' + p_date_start + '/' + p_date_end }),
  },
  comment: {
    get_feed_customers: _ => Helpers.axios({ url: '/facebook/get/feed' }),
    feed_response_update: (data = {}) => Helpers.axios({ url: '/facebook/feed/response/update', method: 'POST', data }),
    feed_project_update: (data = {}) => Helpers.axios({ url: '/facebook/feed/project/update', method: 'POST', data }),
    feed_group_insert: (data = {}) => Helpers.axios({ url: '/facebook/feed/group/update', method: 'POST', data }),
  },
  group: {
    get_group: () => Helpers.axios({ url: '/group/get/all' }),
    get_group_public: () => Helpers.axios({ url: '/group/get/public' }),
    get_group_id: ({ p_id_group }) => Helpers.axios({ url: '/group/get/id/' + p_id_group }),
    group_insert_update: (data = {}) => Helpers.axios({ url: '/group/insert/update', method: 'POST', data }),
    group_delete: (data = {}) => Helpers.axios({ url: '/group/delete', method: 'POST', data })
  },
  profile: {
    get_profile: () => Helpers.axios({ url: '/profile/get/all' }),
    get_profile_id: ({ p_id_profile }) => Helpers.axios({ url: '/profile/get/id/' + p_id_profile }),
    profile_insert_update: (data = {}) => Helpers.axios({ url: '/profile/insert/update', method: 'POST', data }),
    profile_delete: (data = {}) => Helpers.axios({ url: '/profile/delete', method: 'POST', data })
  },
  tags_items: {
    get_tags_items_profile: (data = {}) => Helpers.axios({ url: '/tagsitems/profile/get/all/', data, method: 'POST' }),
    get_tags_items_customers: ({ p_id_customers = '' } = {}) => Helpers.axios({ url: '/tagsitems/customers/get/all/' + p_id_customers }),
    get_tags_items_customers_public: ({ p_id_customers = '' } = {}) => Helpers.axios({ url: '/tagsitems/customers/get/public/' + p_id_customers }),
  },
  feed: {
    get_feed_history: () => Helpers.axios({ url: '/feed/get/history' }),
    get_feed_groups: ({ p_id_feed = '' }) => Helpers.axios({ url: '/facebook/get/feed/groups/' + p_id_feed }),
    feed_group_delete: (data = {}) => Helpers.axios({ url: '/facebook/feed/group/delete', method: 'POST', data }),
    feed_date_active_update: (data = {}) => Helpers.axios({ url: '/facebook/feed/date/active/update', method: 'POST', data }),
    feed_contact_insert: (data = {}) => Helpers.axios({ url: '/facebook/feed/contact/update', method: 'POST', data }),
    feed_contact_delete: (data = {}) => Helpers.axios({ url: '/facebook/feed/contact/delete', method: 'POST', data }),
    get_feed_contacts: ({ p_id_feed = '' }) => Helpers.axios({ url: '/facebook/get/feed/contacts/' + p_id_feed }),
  },
  job: {
    get_job: () => Helpers.axios({ url: '/job/get/all' }),
    get_job_id: ({ p_id_job }) => Helpers.axios({ url: '/job/get/id/' + p_id_job }),
    job_insert_update: (data = {}) => Helpers.axios({ url: '/job/insert/update', method: 'POST', data }),
    job_delete: (data = {}) => Helpers.axios({ url: '/job/delete', method: 'POST', data })
  },
  alert: {
    get_alert_crm: ({ p_date_start, p_date_end }) => Helpers.axios({ url: '/alert/crm/get/all/' + p_date_start + '/' + p_date_end }),
    get_alert_chatbot: ({ p_id_customers }) => Helpers.axios({ url: `/alert/chatbot/get/${p_id_customers}` }),
    alert_chatbot_insert_update: (data = {}) => Helpers.axios({ url: '/alert/chatbot/update', method: 'POST', data }),
  },
  chat: {
    get_chat: ({ p_limit = '' } = {}) => Helpers.axios({ url: '/chat/get/all/' + p_limit }),
    send_crm: ({ p_id_chat }) => Helpers.axios({ url: '/chat/send/crm/' + p_id_chat }),
    chat_det_insert: (data = {}) => Helpers.axios({ url: '/chat/det/insert', method: 'POST', data }),
    finished_chat: (data = {}) => Helpers.axios({ url: '/chat/finished', method: 'POST', data }),
    chat_form_update: (data = {}) => Helpers.axios({ url: '/chat/form/update', method: 'POST', data }),
    get_chat_id: ({ p_id_chat }) => Helpers.axios({ url: '/chat/get/id/' + p_id_chat }),
    chat_insert_update: (data = {}) => Helpers.axios({ url: '/chat/insert/update', method: 'POST', data }),
    chat_delete: (data = {}) => Helpers.axios({ url: '/chat/delete', method: 'POST', data }),
    whatsapp_new_message: (data = {}) => Helpers.axios({ url: '/whatsapp/new/message', method: 'POST', data }),
    send_message_file: (data = {}) => Helpers.axios({ url: '/chat/send/message/file', method: 'POST', data }),
    send_image: (data = {}) => Helpers.axios({ url: '/chat/send/image', method: 'POST', data, hasFile: true }),
    chat_off: (data = {}) => Helpers.axios({ url: '/chat/off', method: 'POST', data }),
    chat_seen_all: (data = {}) => Helpers.axios({ url: '/chat/seen/all', method: 'POST', data }),
    chat_header: (data = {}) => Helpers.axios({ url: '/chat/header', method: 'POST', data }),
    change_notification: (data = {}) => Helpers.axios({ url: '/notification/change', method: 'POST', data }),
    chat_transfer: (data = {}) => Helpers.axios({ url: '/chat/transfer', method: 'POST', data }),
  },
  // ? Nuevo endpoint para supervisor de agente
  chat_agent: {
    get_chat: ({ p_id_customers_contact } = {}) => Helpers.axios({ url: `/chat/agent/get/all/${p_id_customers_contact}` }),
    get_chat_agent: ({ p_id_customers_contact } = {}) => Helpers.axios({ url: `/chat/agent/get/${p_id_customers_contact}` }),
    chat_agent_insert_update: (data) => Helpers.axios({ url: '/chat/agent/insert/update', method: 'POST', data }),
  },
  form: {
    form_insert: (data = {}) => Helpers.axios({ url: '/form/insert', method: 'POST', data }),
    get_form: () => Helpers.axios({ url: '/form/get/' }),
    form_det_delete: (data = {}) => Helpers.axios({ url: '/form/det/delete', method: 'POST', data }),
  },
  quota: {
    get_quota: () => Helpers.axios({ url: '/quota/get/all' }),
    get_quota_id: ({ p_id_quota }) => Helpers.axios({ url: '/quota/get/id/' + p_id_quota }),
    quota_insert_update: (data = {}) => Helpers.axios({ url: '/quota/insert/update', method: 'POST', data }),
    quota_delete: (data = {}) => Helpers.axios({ url: '/quota/delete', method: 'POST', data })
  },
  file: {
    get_file: () => Helpers.axios({ url: '/file/get/all' }),
    get_file_id: ({ p_id_file }) => Helpers.axios({ url: '/file/get/id/' + p_id_file }),
    file_insert_update: (data = {}) => Helpers.axios({ url: '/file/insert/update', method: 'POST', data, hasFile: true }),
    file_delete: (data = {}) => Helpers.axios({ url: '/file/delete', method: 'POST', data })
  },
  answer: {
    get_answer: () => Helpers.axios({ url: '/answer/get/all' }),
    get_answer_id: ({ p_id_answer }) => Helpers.axios({ url: '/answer/get/id/' + p_id_answer }),
    answer_insert_update: (data = {}) => Helpers.axios({ url: '/answer/insert/update', method: 'POST', data }),
    answer_delete: (data = {}) => Helpers.axios({ url: '/answer/delete', method: 'POST', data })
  },
  flow_chat: {
    get_flow_chat: () => Helpers.axios({ url: '/flowchat/get' }),
    get_flow_chat2: () => Helpers.axios({ url: '/flowchat/get2' }),
    get_flow_chat_id: ({ p_id_flow_chat }) => Helpers.axios({ url: '/flowchat/get/id/' + p_id_flow_chat }),
    flow_chat_insert_update: (data = {}) => Helpers.axios({ url: '/flowchat/insert/update', method: 'POST', data }),
    flow_chat_delete: (data = {}) => Helpers.axios({ url: '/flowchat/delete', method: 'POST', data })
  },
  room: {
    get_room: ({ p_id_project }) => Helpers.axios({ url: '/room/get/all/' + p_id_project }),
    get_room_id: ({ p_id_room }) => Helpers.axios({ url: '/room/get/id/' + p_id_room }),
    room_insert_update: (data = {}) => Helpers.axios({ url: '/room/insert/update', method: 'POST', data }),
    room_delete: (data = {}) => Helpers.axios({ url: '/room/delete', method: 'POST', data })
  },
  bot_date: {
    get_bot_date: () => Helpers.axios({ url: '/bot_date/get/all' }),
    get_bot_date_id: ({ p_id_bot_date }) => Helpers.axios({ url: '/bot_date/get/id/' + p_id_bot_date }),
    bot_date_insert_update: (data = {}) => Helpers.axios({ url: '/bot_date/insert/update', method: 'POST', data }),
    bot_date_delete: (data = {}) => Helpers.axios({ url: '/bot_date/delete', method: 'POST', data })
  },
  tag: {
    get_tag: () => Helpers.axios({ url: '/tag/get/all' }),
    get_tag_id: ({ p_id_tag }) => Helpers.axios({ url: '/tag/get/id/' + p_id_tag }),
    tag_insert_update: (data = {}) => Helpers.axios({ url: '/tag/insert/update', method: 'POST', data }),
    tag_delete: (data = {}) => Helpers.axios({ url: '/tag/delete', method: 'POST', data })
  },
}
export default Controllers;