import React from 'react'

import Preloading from "../../Resources/preloading.svg";
import PreloadingTelcotec from "../../Resources/preloading-telcotec.svg";

export default function Loading() {
  const source = window?.location.pathname.includes("login2") ||
    window?.location.hostname.includes("telcotech")
    ? PreloadingTelcotec
    : Preloading;

  return (
    <div style={styleDiv}>
      <img src={source} alt="Preloading" />
    </div>
  )
}

const styleDiv = {
  width: "100%",
  height: "100%",
  display: "flex",
  position: "fixed",
  alignItems: "center",
  justifyContent: "center",
}
