import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import Controllers from '../../Api/Controllers'
import { Input, SwitchToggle, FormCustom, Select, Radio, TextArea } from '../../Components/Form/Form'
import ReactPlaceholder from 'react-placeholder'
import { useSelector } from 'react-redux'
import Helpers from '../../Components/Helpers/Helpers'
import Button from 'react-bootstrap/Button'
import {toast} from 'react-toastify'

const FileForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_file: data.id_file,
        p_description_file: data.description_file,
        p_id_customers: data.id_customers,
        p_enabled_file: data.enabled_file,
        p_path_file: data.path_file,
        p_type_file: data.type_file,
        p_text_file: data.text_file,
    })

    const [rowFile, setRowFile] = useState('');

    const masterUser = useSelector(store => store.session.master_user)
    const [ready, setReady] = useState(false)
    const [customers, setCustomers] = useState([])
    useEffect(() => {
        if (masterUser && isNaN(parseInt(data.id_file))) {
            Controllers.customers.get_customers_public()
            .then(res => {
                setReady(true)
                setCustomers(res.data)
            })
        } else {
            setReady(true)
        }
    }, [])
    
    const dataSubmit = useCallback(() => {
        let formData = new FormData()

        for (const key in row) {
            if (key === 'p_path_file') {
                formData.append('p_path_file', rowFile ? rowFile : (row.p_path_file ? row.p_path_file : ''))
            } else if (key === 'p_text_file') {
                formData.append('p_text_file', row.p_text_file ? row.p_text_file : '')
            } else {
                formData.append(key, row[key])
            }
        }
        return formData
    }, [rowFile, row])

    return <ReactPlaceholder rows={15} showLoadingAnimation ready={ready}>
        <FormCustom dataSubmit={dataSubmit()} onSubmit={({ e, data }) => {
            if (!row.p_id_customers && masterUser) {
                Helpers.toast.construct({ response: 'warning', message: 'Seleccionar Cliente', toast })
                return
            }

            if (!row.p_text_file && !row.p_path_file && !rowFile) {
                Helpers.toast.construct({ response: 'warning', message: 'Debe ingresar por lo menos un Mensaje o Imagen', toast })
                return
            }


            onSubmit({ e, data })
        }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            {masterUser ? (isNaN(parseInt(data.id_file))? <Select
                options={customers.map(c => {
                    return {
                        label: c.name_customers,
                        value: c.id_customers
                    }
                })}
                text="Cliente"
                classNameParent="col-12 mb-2"
                icon="far fa-building"
                onChange={e => setRow({ ...row, p_id_customers: e.value })}
            /> : <Input
                text="Cliente"
                placeholder="Cliente"
                classNameParent="col-12 mb-2"
                icon="far fa-building"
                readOnly
                defaultValue={data.name_customers}
            />) : ''}
            <Input
                text="Descripción"
                placeholder="Descripción"
                classNameParent="col-12 mb-2"
                icon="far fa-user"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.description_file}
                onChange={e => setRow({ ...row, p_description_file: e.currentTarget.value })}
                maxLength={255}
            />
            <Input
                text="Archivo"
                type="file"
                placeholder="Archivo"
                classNameParent="col-12 mb-2"
                invalid="El campo es obligatorio."
                accept="image/png, image/jpg" /* application/pdf */
                onChange={e => {
                    if (e.currentTarget.files.length === 0) {
                        setRowFile('')
                    } else {
                        let files = e.currentTarget.files
                        let selectedFile = files[0]
                        /* let reader = new FileReader()
                        reader.onload = event => {
                            setRowFile(event.target.result)
                            setRow({ ...row, p_path_file: selectedFile })
                        }
                        reader.readAsDataURL(selectedFile) */
                        /* setRowFile(event.target.result) */
                        setRowFile(selectedFile)
                    }
                }}
                maxLength={255}
            />
            <div className="col-12 mb-2">
                {!rowFile && data.path_file && !isNaN(parseInt(data.id_file)) ? <a target="_blank" href={Helpers.config.resourcesUrl({ url: data.path_file })}><Button size="sm">Ver Archivo</Button></a> : ''}
            </div>
            <TextArea
                text="Mensaje"
                placeholder="Mensaje"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                defaultValue={data.text_file}
                onChange={e => setRow({ ...row, p_text_file: e.currentTarget.value })}
                rows="7"
            />
            <SwitchToggle
                id="enabled-file"
                defaultChecked={parseInt(data.enabled_file) === 1}
                onChange={e => setRow({ ...row, p_enabled_file: e.currentTarget.checked ? 1 : 0 })}
            />
        </FormCustom>
    </ReactPlaceholder>
}

FileForm.defaultProps = {
    data: {
        id_file: '',
        description_file: '',
        id_customers: '',
        name_customers: '',
        path_file: '',
        type_file: '',
        text_file: '',
        enabled_file: 0
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default FileForm;