import React, { useState, useEffect, useCallback, useRef } from 'react';
import { RoundShape, TextBlock } from 'react-placeholder/lib/placeholders';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import Card from 'react-bootstrap/esm/Card';
import { useSelector } from 'react-redux';
import Tabs from 'react-bootstrap/Tabs';
import Picker from 'emoji-picker-react';
import { toast } from 'react-toastify';
import { Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import data from './data'

import { FormCustom, Input, Select, SwitchToggleChecked, TextArea } from '../../Components/Form/Form'
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import Helpers from '../../Components/Helpers/Helpers'
import TransImage from '../../assets/trans.png' 
import Controllers from '../../Api/Controllers'
import FormUpdate from './FormUpdate'
import useWS from '../../hooks/useWS';

const http = process.env.REACT_APP_API_URL_CLIENT

const styleCard = {
  borderRadius: 0
}

const styleProfileImage = {
  borderRadius: 50
}

const heightStartMessage = '65px'
const heightContent = '500px'
const heightBox = '65px'

export default function AgentsChats(props) {
  const [soundActive, setSoundActive] = useState(undefined)
  const [customersContactList, setCustomersContactList] = useState([])
  const [loadSubmitTransfer, setLoadSubmitTransfer] = useState(false)
  const [modalTransfer, setModalTransfer] = useState(false)
  const [loadingChats, setLoadingChats] = useState(false)
  const [showSendCRM, setShowSendCRM] = useState(false)
  const [startWrite, setStartWrite] = useState(false)
  const [chatsHeader, setChatsHeader] = useState([])
  const [currentChat, setCurrentChat] = useState({})
  const [loadAgent, setLoadAgent] = useState(false)
  const [showEmoji, setShowEmoji] = useState(false)
  const [newMessage, setNewMessage] = useState('')
  const [agent, setAgent] = useState(undefined);
  const [chatAdded, setChatAdded] = useState([])
  const [websoc, setWebsoc] = useState(null)
  const [isObs, setIsObs] = useState(false)
  const [ready, setReady] = useState(false)
  const [agents, setAgents] = useState([])
  const [search, setSearch] = useState('')
  const [files, setFiles] = useState([])
  const [chats, setChats] = useState([])
  const [limit, setLimit] = useState(1)
  
  const [filter, setFilter] = useState({
    value: 1,
    label: 'Mi Chats',
  },);
  const [row, setRow] = useState({
    p_message: '',
    p_id_chat: ''
  })
  const [transferChat, setTransferChat] = useState({
    comment: '',
    id_customers_contact: '',
    current_chat: {}
  })
  
  const idCustomers = useSelector(store => store.session.id_customers)
  const store = useSelector(store => store.session)
  const messagesEndRef = useRef(null)
  const ws = useWS()

  useEffect(() => setSoundActive(parseInt(store.notification_multiagente) === 1), [])


  // * Llamado de los endpoint en promise all
  const loadAll = _ => {
    setReady(false)
    // Promise.all([
      // Controllers.chat.get_chat(),
      // Controllers.file.get_file(),
      // Controllers.customers_contact.get_customers_contact(),
    // ]).then(_ => {
      setChats(data.chats)
      setChatsHeader(data.header)
      // setFiles(res[1].data.filter(d => parseInt(d.enabled_file) === 1))
      // setAgents(res[2].data)
      // setCustomersContactList(
      //   res[2].data
      //     .filter(c => parseInt(c.enabled_contact) === 1 && parseInt(c.is_agent) === 1)
      //     .map(r => ({
      //       label: r.name_contact,
      //       value: r.id_customers_contact
      //     }))
      // )
      setReady(true)
    // })
  }

  // * Aqui hace el llamado de los endpoint de chat, archivo y agentes
  useEffect(() => loadAll(), [])

  // * Aqui mantiene el socker abierto
  useEffect(() => {
    if (Helpers.config.isProduction) {
      let conn = new WebSocket(ws);

      conn.onopen = function () {
        conn.send(JSON.stringify({
          command: "subscribe",
          channel: 'CLIENTE_' + idCustomers
        }));
      };
      window.onunload = function () {
        conn.close();
      }
      setWebsoc(conn)
    } else {
      let conn = new WebSocket("ws://localhost:6001/")
      conn.onopen = function () {
        conn.send(JSON.stringify({
          command: "subscribe",
          channel: 'CLIENTE_' + idCustomers
        }));
      };
      window.onunload = function () {
        conn.close();
      }
      setWebsoc(conn)
    }
  }, [idCustomers])

  // * detecta en scroll fin de chat y hace un siguente llamando para traer mas chat
  useEffect(() => {
    if (ready) {
      setTimeout(_ => {
        let divChat = document.getElementById('observer_chats')
        let lazyImageObserver = new IntersectionObserver(function (
          entries,
          observer
        ) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              setIsObs(true)
            }
          });
        }, {
          rootMargin: '0px 0px 0px 0px',
        });

        lazyImageObserver.observe(divChat);
      }, 0)
    }
  }, [ready])

  useEffect(() => {
    if (isObs) {
      setLoadingChats(true)
      setIsObs(false)
    }
  }, [isObs])

  useEffect(_ => {
    if (limit !== null && loadingChats) {
      Controllers.chat.get_chat({ p_limit: limit }).then(res => {
        if (res.data.chats.length > 0) {
          setChatAdded(res.data.chats)
          setLoadingChats(false)
          setLimit(limit + 1)
          document.querySelector('.list-chats').scrollTo(0, document.querySelector('.list-chats').scrollHeight / 2)
        } else {
          setLoadingChats(false)
          setLimit(null)
        }
      })
    } else {
      setLoadingChats(false)
    }
  }, [limit, loadingChats])

  useEffect(_ => {
    if (chatAdded.length) {
      setChats([...chats, ...chatAdded])
      setChatAdded([])
    }
  }, [chatAdded, chats, filter])

  const executeNotification = ({ title, message, chatFinded }) => {
    let notification = new Notification(
      title,
      {
        body: message,
        icon: `${http}/favicon.ico`
      }
    );


    notification.onclick = e => {
      e.preventDefault();
      if (chatFinded !== null) {
        let hasSeen = false
        setChats(chats.map(cc => {
          if (parseInt(cc.id_chat) === parseInt(chatFinded.id_chat)) {
            if (cc.messages.filter(mm => parseInt(mm.seen) === 0 && parseInt(mm.from_user) === 0).length > 0) {
              hasSeen = true
              cc.messages = cc.messages.map(mm => {
                mm.seen = 1
                return mm
              })
            }
          }
          return cc
        }))
        if (hasSeen) {
          Controllers.chat.chat_seen_all({
            p_id_chat: chatFinded.id_chat
          })
        }
        setCurrentChat(chatFinded)
        scrollDownMessage()
      }
      notification.close()
    }

    // console.log(soundActive)
    if (soundActive) {
      let myAudio = new Audio(`${http}/assets/audios/multiagente.mp3`);
      myAudio.play();
    }

    setTimeout(() => {
      notification.close()
    }, 3000);
  }

  const notifyMe = ({ title, message, chatFinded = null }) => {
    if (!("Notification" in window)) {

    }
    else if (Notification.permission === "granted") {
      executeNotification({ title, message, chatFinded })
    }
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          executeNotification({ title, message, chatFinded })
        }
      });
    }
  }
  useEffect(() => {
    if (websoc !== null) {
      websoc.onmessage = e => {
        let r = JSON.parse(e.data)
        if (r.type === 'NEW_CHAT') {
          if (chats.some(c => parseInt(c.id_chat) === parseInt(r.data.id_chat))) {
            setChats(chats.map(c => {
              if (parseInt(c.id_chat) === parseInt(r.data.id_chat)) {
                c = r.data
              }
              return c
            }))

            if (parseInt(currentChat.id_chat) === parseInt(r.data.id_chat)) {
              setCurrentChat(r.data)
            }
          } else {
            setChats([...chats, r.data])
          }
          notifyMe({ title: r.data.name_chat, message: r.data.message, chatFinded: r.data })
        } else if (r.type === 'MESSAGE') {
          let nameChat = {}
          let chatFinded = null

          setChats(chats.map(c => {
            if (parseInt(c.id_chat) === parseInt(r.data.detail.id_chat)) {
              if (parseInt(currentChat.id_chat) === parseInt(c.id_chat)) {
                r.data.detail.seen = 1
                Controllers.chat.chat_seen_all({
                  p_id_chat: c.id_chat
                })
              }

              c.send_message_react = r.data.chat.send_message_react;
              c.expired_whatsapp = r.data.chat.expired_whatsapp;
              c.status_chat = 0;
              c.message = r.data.detail.message
              c.date_created = r.data.detail.date_created
              c.messages = [...c.messages, r.data.detail]
              c.id_freshchat = 'test1'
              nameChat = c.name_chat
              chatFinded = c
            }
            return c
          }).sort(function (a, b) {
            return new Date(b.date_created) - new Date(a.date_created);
          }))
          notifyMe({ title: nameChat, message: r.data.detail.message, chatFinded })
          scrollDownMessage()
        }

        Controllers.chat.chat_header().then(res => {
          console.log(res.data)
          setChatsHeader(res.data)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websoc, chats, currentChat, soundActive])

  useEffect(_ => {
    document.title = `(${chats.filter(c => parseInt(c.status_chat) === 0).length}) Multiagente - Tecnicom`
  }, [chats])

  const heightBoxMessage = useCallback(() => {
    if (startWrite) {
      return {
        heightMessage: heightStartMessage,
        rows: 1,
        heightContentMessage: `calc(${heightContent} - ${heightBox} - ${heightStartMessage})`,
        heightContentData: `calc(${heightContent} - ${heightBox}`,
      }
    } else {
      return {
        heightMessage: heightBox,
        rows: 1,
        heightContentMessage: `calc(${heightContent} - ${heightBox} - ${heightBox})`,
        heightContentData: `calc(${heightContent} - ${heightBox}`,
      }
    }
  }, [startWrite])

  const scrollDownMessage = _ => {
    setTimeout(() => {
      messagesEndRef?.current?.scrollIntoView(false)
    }, 0);
  }

  const [key, setKey] = useState(-1)
  const [modalMessage, setModalMessage] = useState(false)

  const sendMessage = ({ idFile, idChat }) => {
    if (idFile) {
      Controllers.chat.send_message_file({
        p_id_file: idFile,
        p_id_chat: idChat
      })
        .then(res => {
          Helpers.toast.construct({ ...res, toast })
        })
    }
  }

  const chatFilters = useCallback(_ => {
    return chats.filter(c => {
      if (parseInt(key) >= 0) {
        if (search) {
          let matchMessage = c.messages.some(m => {
            if (m?.message) {
              return m.message.toLowerCase().includes(search.toLowerCase())
            }

            return m
          })

          return parseInt(c.status_chat) === parseInt(key) && (c.name_chat.toLowerCase().includes(search.toLowerCase()) || c.number_chat.toLowerCase().includes(search.toLowerCase()) || c.seller.name_contact.toLowerCase().includes(search) || matchMessage)
        }

        return parseInt(c.status_chat) === parseInt(key)
      }
      if (search) {
        /* let matchMessage = c.messages.some(m => {
            if (m?.message) {
                return m.message.toLowerCase().includes(search.toLowerCase())
            }
            return m
        }) */

        return c.name_chat.toLowerCase().includes(search.toLowerCase()) || c.number_chat.toLowerCase().includes(search.toLowerCase()) || c.seller.name_contact.toLowerCase().includes(search)/*  || matchMessage */
      }

      return true
    }).sort(function (a, b) {
      return new Date(b.date_created) - new Date(a.date_created);
    })
  }, [chats, key, search])

  const sumChats = useCallback(_ => {
    let total = 0
    chatsHeader.forEach(c => {
      total += parseInt(c.qty)
    })
    return total
  }, [chatsHeader])


  const cboCustomerContact = useCallback(() => {
    if (!transferChat.id_customers_contact) {
      return ''
    }

    let finded = customersContactList.find(j => parseInt(j.value) === parseInt(transferChat.id_customers_contact))
    if (!finded) {
      return ''
    }

    return finded
  }, [transferChat.id_customers_contact, customersContactList])

  const handleTransfer = _ => {
    setTransferChat({
      comment: '',
      id_customers_contact: '',
      current_chat: currentChat
    })
    setModalTransfer(true)
  }
  
  const handleSubmit = () => {
    Controllers.chat.chat_det_insert(row)
      .then(res => {
        if (res.response !== 'success') {
          Helpers.toast.construct({ ...res, toast })
          return
        }
        setChats(chats.map(c => {
          if (parseInt(c.id_chat) === currentChat.id_chat) {
            c.message = row.p_message
            c.status_chat = 1;
            c.messages.push({
              message: row.p_message,
              from_user: 1,
              hour_message: Helpers.date.get({ format: '%d/%m/%Y %H:%i %a' }),
            })
          }

          return c
        }))
        scrollDownMessage()
        setRow({ ...row, p_message: '' })
        Controllers.chat.chat_header().then(res => setChatsHeader(res.data))
      })
      .catch(_ => Helpers.toast.construct({ response: 'warning', message: 'No se ha pddido enviar el mensaje.', toast }))
  }

  const searchSocialNetwork = (res) => {
    let icon = '', color = '';

    if (res === 'FACEBOOK') {
      icon = 'fab fa-facebook'
      color = '#1094F4'
    } else if (res === 'WHATSAPP') {
      icon = 'fab fa-whatsapp'
      color = '#0DC143'
    } else if (res === 'TELEGRAM') {
      icon = 'fab fa-telegram'
      color = '#32AADE'
    } else if (res === "IG") {
      icon = 'fab fa-instagram'
      color = '#833AB4'
    } else {
      icon = 'fa fa-globe'
      color = ''
    }

    return { icon , color }
  }

  const value = () => {
    const val = agent && agents.length > 0 ? agents?.find(d => d.id_customers_contact === agent) : null
    if (val) {
      return {value: val.id_customers_contact, label: val.name_contact}
    }
    return ''
  }

  const valueFilter = [
    {
      value: 1,
      label: 'Mi Chats',
    },
    {
      value: 2,
      label: 'Todos los chats',
    },
    {
      value: 3,
      label: 'Por agente',
    },
  ]

  return <>
    <header>
      <Row className="d-flex">
        <Select
          value={filter}
          text="tipo de chat"
          options={valueFilter}
          classNameParent="col-6 w-100 mb-2"
          onChange={(e) => setFilter(e)}
        />
        {filter?.value === 3 && (
          <Select
            text="Seleccione agente"
            value={value()}
            isLoading={loadAgent}
            options={agents.length > 0 ? agents.map(a => ({value: a.id_customers_contact, label: a?.name_contact})) : []}
            classNameParent="w-100 col-6 mb-2"
            onChange={(e) => setAgent(e.value)}
          />
        )}
      </Row>

    </header>
    <>
      <div className="d-flex justify-content-between">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => {
            setKey(k)
            setCurrentChat({})
          }}
        >
          <Tab eventKey={-1} title={`Todos (${sumChats()})`}>
          </Tab>
          <Tab eventKey={0} title={`No Atendidos (${chatsHeader.some(c => parseInt(c.status_chat) === 0) ? chatsHeader.find(c => parseInt(c.status_chat) === 0)?.qty : 0})`}>
          </Tab>
          <Tab eventKey={1} title={`Atendidos (${chatsHeader.some(c => parseInt(c.status_chat) === 1) ? chatsHeader.find(c => parseInt(c.status_chat) === 1)?.qty : 0})`}>

          </Tab>
          <Tab eventKey={2} title={`Enviados a CRM (${chatsHeader.some(c => parseInt(c.status_chat) === 2) ? chatsHeader.find(c => parseInt(c.status_chat) === 2)?.qty : 0})`}>

          </Tab>
        </Tabs>
        <div className="align-self-center">
          <Button onClick={_ => {
            Controllers.chat.change_notification({
              p_notification_multiagente: soundActive ? 0 : 1
            })
            setSoundActive(!soundActive)
          }} className="mr-3" size="sm" variant={soundActive ? 'dark' : 'outline-secondary'}>
            <i className={soundActive ? 'far fa-bell-slash mr-2' : 'far fa-bell mr-2'}></i>
            <span>{soundActive ? 'Desactivar sonido' : 'Activar sonido'}</span>
          </Button>
          <Button size="sm" onClick={_ => setModalMessage(true)}>Nuevo Mensaje</Button>
        </div>
      </div>
      <div className="multiagente_content">
      <div className="list-chats">
        <div className="p-2">
            <Input
              type="text"
              placeholder="Buscar..."
              onChange={e => setSearch(e.currentTarget.value)}
            />
        </div>
          {!ready ? [1,2,3,4,5].map((loa) => (
            <div className="item-loading-chat" key={loa}>
              <RoundShape color='#E0E0E0' style={ { width: 50, height: 50 } } />
              <TextBlock color='#E0E0E0' rows={2} style={{margin: 'auto'}} />
            </div>
          )): (
            <Card style={styleCard}>
              { React.Children.toArray(chatFilters().map((c, i) => {
                const { color, icon } = searchSocialNetwork(c.resource)
                

                let showMessageComplete = c.message?.toString()?.length < 30

                return <>
                  <Card.Body style={{
                    cursor: 'pointer',
                    height: 87,
                    position: 'relative'
                  }} className={'d-flex chat_item ' + (c.id_chat === currentChat.id_chat ? 'chat_selected ' : '')} onClick={_ => {
                    let hasSeen = false
                    setChats(chats.map(cc => {
                      if (parseInt(cc.id_chat) === parseInt(c.id_chat)) {
                        if (cc.messages.filter(mm => parseInt(mm.seen) === 0 && parseInt(mm.from_user) === 0).length > 0) {
                          hasSeen = true
                          cc.messages = cc.messages.map(mm => {
                            mm.seen = 1
                            return mm
                          })
                        }
                      }
                      return cc
                    }))
                    if (hasSeen) {
                      Controllers.chat.chat_seen_all({
                        p_id_chat: c.id_chat
                      })
                    }
                    setCurrentChat(c)
                    setRow({
                      p_id_chat: c.id_chat
                    })
                    scrollDownMessage()
                  }}>
                    <div className="pr-3">
                      <img width="50" style={styleProfileImage} src={c.photo_chat ? c.photo_chat : Helpers.config.resourcesUrl({ url: '/images/dashboard/not-profile-picture.png' })} />
                    </div>
                    <div>
                      <h6 style={{ fontSize: 12 }}>{c.name_chat}</h6>
                      <small className="text-muted">{showMessageComplete ? c.message : (c?.message ? c?.message?.toString().substr(0, 25) : '') + '...'}</small>
                    </div>
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      right: 10,
                      color,
                      fontSize: 20
                    }}><i className={icon}></i></div>
                    <div className="badge badge-dark" style={{
                      position: 'absolute',
                      bottom: 2,
                      left: 10,
                      fontSize: 10
                    }}>{c?.seller?.name_short ? c?.seller?.name_short : c?.seller?.name_contact}</div>
                    <div /* className="badge badge-dark" */ style={{
                      position: 'absolute',
                      bottom: parseInt(c.status_chat) === 2 ? 7 : 5,
                      right: parseInt(c.status_chat) === 2 ? 14 : 12,
                      fontSize: parseInt(c.status_chat) === 2 ? 14 : 12,
                      width: 12,
                      height: 12,
                      borderRadius: 12
                    }} className={parseInt(c.status_chat) === 0 ? 'bg-danger' : (parseInt(c.status_chat) === 1 ? 'bg-success' : 'text-info')}>{parseInt(c.status_chat) === 2 ? <i className="fas fa-check-circle"></i> : ''}</div>
                    {c.messages.filter(mm => parseInt(mm.seen) === 0 && parseInt(mm.from_user) === 0).length > 0 ? <div /* className="badge badge-dark" */ style={{
                      position: 'absolute',
                      bottom: 24,
                      right: 7,
                      fontSize: 12,
                      width: 22,
                      height: 22,
                      borderRadius: 12
                    }} className={'d-flex justify-content-center align-items-center text-white bg-success'}>{c.messages.filter(mm => parseInt(mm.seen) === 0 && parseInt(mm.from_user) === 0).length}</div> : ''}
                  </Card.Body>
                  {i + 1 === chats.length ? '' : <hr className="p-0 m-0" />}
                </>
              }))}
            </Card>
          )}
        <div id="observer_chats" style={{ height: 30, width: '100%' }}>{loadingChats ? <><i className="fa fa-circle-notch fa-spin text-primary mr-2 text-center"></i>Cargando</> : ''}</div>
        </div>
        <div style={{
          border: '1px solid #EBEBE9',
        }}>
          <div className="d-flex p-2" style={{
            backgroundColor: '#F2F3F1',
            minHeight: heightBox
          }}>
            <div className="d-flex justify-content-between w-100">
              <div className="d-flex">
                <div>
                  {currentChat?.id_chat ? <img width="50" style={styleProfileImage} src={currentChat?.photo_chat ? currentChat.photo_chat : Helpers.config.resourcesUrl({ url: '/images/dashboard/not-profile-picture.png' })} /> : ''}
                </div>
                <div className="align-self-center ml-3">
                  <h6 className="m-0">{currentChat?.name_chat} {currentChat?.number_chat}</h6>
                </div>
              </div>
              <div className="align-self-center d-flex">
                {currentChat?.id_chat ? <SwitchToggleChecked
                  classNameParent={'align-self-center mr-4'}
                  id={'id_chat_' + currentChat.id_chat}
                  textCheck="Apagar Bot"
                  textUnChecked="Encender Bot"
                  checked={parseInt(currentChat.chat_off) === 1}
                  onChange={e => {
                    setChats(chats.map((c2) => {
                      if (parseInt(c2.id_chat) === parseInt(currentChat.id_chat)) {
                        c2.chat_off = e.currentTarget.checked ? 1 : 0
                      }
                      return c2
                    }))

                    Controllers.chat.chat_off({
                      p_id_chat: currentChat.id_chat,
                      p_chat_off: e.currentTarget.checked ? 1 : 0
                    }).then(res => {
                    })
                  }}
                /> : ''}
                {currentChat?.id_chat && <div className='align-self-center mr-4'>
                  <img
                    alt=""
                    width={ 30 }
                    src={
                      TransImage
                      //Helpers.config.resourcesUrl({
                      // url: '/images/dashboard/transferencia-chat.png'
                      //})
                    }
                    onClick={ handleTransfer }
                    className="cur-pointer"
                  />
                </div>}
                {currentChat?.id_chat && parseInt(currentChat?.status_chat) === 0 && <div className='align-self-center'>
                  <img onClick={_ => {
                    Controllers.chat.finished_chat({
                      p_id_chat: currentChat.id_chat
                    }).then(res => {
                      if (res.response !== 'success') {
                        Helpers.toast.construct({ ...res, toast })
                        return
                      }

                      setChats(chats.map(c => {
                        if (parseInt(c.id_chat) === currentChat.id_chat) {
                          c.status_chat = 1;
                        }

                        return c
                      }))

                      Controllers.chat.chat_header().then(res => {
                        setChatsHeader(res.data)
                      })
                    }).catch(_ => Helpers.toast.construct({ response: 'warning', message: 'No se ha pddido enviar el mensaje.', toast }))
                  }} src={Helpers.config.resourcesUrl({
                    url: '/images/dashboard/chat-finished.png'
                  })} alt="" width={30} className="cur-pointer" />
                </div>}
              </div>
            </div>
          </div>
          <div className="p-2 bg-white multiagente_content_message" style={{
            backgroundImage: `url(${Helpers.config.resourcesUrl({ url: '/images/dashboard/chat_fondo.png' })})`,
            overflowY: 'auto'
          }}>
            {React.Children.toArray(currentChat?.messages?.map(m => {
              if (parseInt(m.is_message_intern) === 1) {
                return <div className='alert alert-info mr-4 ml-4'>
                  <div className='mb-2'>{m.message}</div>

                  <div className='d-flex justify-content-between'>
                    <span className="text-muted" style={{
                      fontSize: '9px',
                      fontStyle: 'italic'
                    }}>Transferido por: <strong>{m.name_contact}</strong></span>
                    <span className="text-muted" style={{
                      fontSize: '9px',
                      fontStyle: 'italic'
                    }}>{m.date_message} {m.hour_message}</span>
                  </div>
                </div>
              }

              return <div className={'d-flex justify-content-' + (parseInt(m.from_user) === 0 ? 'start' : 'end')}>
                <div style={{
                  backgroundColor: parseInt(m.from_user) === 0 ? '#E9F3FA' : '#FFFFFF',
                  width: '50%',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column'
                }} className="p-3 rounded mb-4">
                  {m.type === 'VOICE' ? <>
                    {m.audio.includes('pdf') || m.audio.includes('xlsx') || m.audio.includes('docx') ? <strong><a href={m.audio} target="_blank">Abrir archivo</a></strong> : <video style={{ paddingBottom: 10 }} controls>
                      <source src={m.audio} type="video/mp4"></source>
                      Your browser does not support the video tag.
                    </video>}
                  </> : (m.type === 'FILE' ? <>
                    {m?.path_file ? <strong><a href={m.path_file} target="_blank" rel="noreferrer">Abrir archivo</a></strong> : ''}
                  </> : <>
                    {m?.photo ? <img src={m.photo} alt={m.name_contact} style={{
                      maxWidth: 200,
                      width: '100%',
                      paddingBottom: 10,
                      maxHeight: 250,
                      objectFit: 'cover',

                    }} /> : ''}
                  </>)}
                  {m.message ? <div style={{ marginBottom: 10 }}>{m.message}</div> : ''}
                  <span className="text-muted" style={{
                    position: 'absolute',
                    right: '5%',
                    bottom: '5%',
                    fontSize: '8px',
                    fontStyle: 'italic'
                  }}>{m.date_message} {m.hour_message}</span>
                </div>
                <br />
              </div>
            }))}
            <div ref={messagesEndRef} />
          </div>
          <div className="p-3 d-flex align-items-center" style={{
            backgroundColor: '#F2F3F1',
            height: heightBoxMessage().heightMessage
          }}>
            {parseInt(currentChat.chat_off) === 0 ? <>
              {currentChat.resource === 'WHATSAPP' && parseInt(currentChat.expired_whatsapp) === 1 ? '' : <div className="align-self-center cur-pointer" style={{
                width: '5%',
                position: 'relative'
              }}>
                {showEmoji ? <span className="fa fa-times" onClick={_ => setShowEmoji(false)}></span> : <span onClick={_ => setShowEmoji(true)}>🙂</span>}

                <div style={{
                  position: 'absolute',
                  top: -343,
                  display: showEmoji ? 'inline-block' : 'none'
                }}>
                  <Picker onEmojiClick={(event, emojiObject) => {
                    setRow({ ...row, p_message: (row.p_message ? row.p_message : '') + ' ' + emojiObject.emoji })
                  }} />
                </div>
              </div>}
              <div className="align-self-center d-flex" style={{ width: '95%' }}>
                {currentChat.resource === 'WHATSAPP' && parseInt(currentChat.expired_whatsapp) === 1 ? <div className="d-flex flex-column w-100">
                  {parseInt(currentChat.send_message_react) === 1 ? <label className="text text-info">Esperando respuesta del cliente</label> : ''}
                  {parseInt(currentChat.send_message_react) === 0 ? <Button block onClick={_ => {
                    Controllers.chat.whatsapp_new_message({
                      p_number: currentChat.number_chat,
                      p_id_plantilla: 1
                    }).then(_ => {

                    }).catch(_ => {

                    }).finally(_ => {
                      setModalMessage(false)
                      Helpers.toast.construct({ response: 'success', message: 'Mensaje enviado', toast })
                      scrollDownMessage()
                      Controllers.chat.chat_header().then(res => {
                        setChatsHeader(res.data)
                      })
                    })
                  }}>Reactivar conversación</Button> : ''}
                </div> :
                  <FormCustom
                    className="w-100"
                    dataSubmit={ row }
                    // onSubmit={ _ => {
                    // }}
                    viewSubmit={ false }>
                  <TextArea
                    value={row.p_message ? row.p_message : ''}
                    classNameParent="w-100"
                    size=""
                    placeholder="Escribe un mensaje aquí"
                    rows={heightBoxMessage().rows}
                    onFocus={_ => {
                      setStartWrite(true)
                      setShowEmoji(false)
                    }}
                    onBlur={_ => setStartWrite(false)}
                    onChange={e => setRow({ ...row, p_message: e.currentTarget.value })}
                    required
                    append={[
                      <Button style={{margin: '0 5px'}} onClick={handleSubmit}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send-fill" viewBox="0 0 16 16">
                          <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
                        </svg>
                      </Button>,
                      // <DropdownButton
                      //   as={ButtonGroup}
                      //   key={'up'}
                      //   id={`dropdown-button-drop-${'up'}`}
                      //   drop={'up'}
                      //   variant="secondary"
                      //   title=""
                      // >
                      //   {currentChat?.id_chat ? React.Children.toArray(files.map(f => {
                      //     return <Dropdown.Item eventKey="1" onClick={_ => {
                      //       sendMessage({ idFile: f.id_file, idChat: currentChat.id_chat })
                      //     }}>{f.description_file}</Dropdown.Item>
                      //   })) : ''}
                      // </DropdownButton>,
                      <>
                        <Button onClick={() => {
                          console.log('hola')
                          // txt_image_add.click()
                        }}>
                          <i className="fa fa-image btn-xs"></i>
                        </Button>
                        <input type="file" id="txt_image_add" className="d-none" accept="image/*" onChange={e => {
                          if (e.currentTarget.files.length > 0) {
                            let formData = new FormData()

                            formData.append('p_id_chat', currentChat.id_chat)
                            formData.append('p_image', e.currentTarget.files[0])

                            Controllers.chat.send_image(formData).then(res => {
                            })
                          }
                        }} />
                      </>
                    ]}
                  />
                </FormCustom>}
              </div>
            </> : (parseInt(currentChat.chat_off) === 1 ? <label className="text text-info">Chatbot está encendido para esta conversación.</label> : '')}
          </div>
        </div>
        <div style={{
          border: '1px solid #EBEBE9',
        }}>
          <div className="d-flex p-2" style={{
            backgroundColor: '#F2F3F1',
            minHeight: heightBox
          }}>
            <div className="align-self-center ml-3">
              <h6 className="m-0">Información de contacto</h6>
            </div>
          </div>
          <div className="p-2 bg-white multiagente_form" style={{
            overflowY: 'auto'
          }}>
            {React.Children.toArray(currentChat?.form?.map((m, i) => {
              if (m.type === 'TEXT' || m.type === 'EMAIL' || m.type === 'SELECT' || m.type === 'TEXTAREA') {
                return <>
                  <FormUpdate idChat={currentChat?.id_chat} message={m} onCallback={({ currentValue }) => {
                    setChats(chats.map(c => {
                      if (currentChat.id_chat === c.id_chat) {
                        c.form = c.form.map((m2, i2) => {
                          if (i2 === i) {
                            m2.value = currentValue
                          }

                          return m2
                        })
                      }

                      return c
                    }))
                  }} />
                </>
              }
              return ''
            }))}
            {React.Children.toArray(currentChat?.formAdd?.map((m, i) => {
              if (m.type === 'TEXT' || m.type === 'EMAIL' || m.type === 'SELECT' || m.type === 'TEXTAREA') {
                return <>
                  <FormUpdate idChat={currentChat?.id_chat} message={m} onCallback={({ currentValue }) => {
                    setChats(chats.map(c => {
                      if (currentChat.id_chat === c.id_chat) {
                        c.formAdd = c.formAdd.map((m2, i2) => {
                          if (i2 === i) {
                            m2.value = currentValue
                          }

                          return m2
                        })
                      }

                      return c
                    }))
                  }} />
                </>
              }
              return ''
            }))}
            {currentChat?.id_chat ? <Button size="sm" onClick={_ => setShowSendCRM(true)}>Enviar a CRM</Button> : ''}
          </div>
        </div>
      </div>
      <SweetAlert
        icon="delete"
        show={showSendCRM}
        showCancelButton
        onCancel={_ => setShowSendCRM(false)}
        onConfirm={_ => {
          Controllers.chat.send_crm({
            p_id_chat: currentChat.id_chat
          }).then(res => {
            setChats(chats.map(c => {
              if (parseInt(c.id_chat) === currentChat.id_chat) {
                c.status_chat = 2;
              }

              return c
            }))
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
              setShowSendCRM(false)
            }
          }).catch(req => Helpers.promise.catch({ req, toast }))
        }}
        title={<>¿Desea enviar lead a CRM?</>}
        subtitle={<>Envío de lead a CRM</>}
        confirmButtonText="¡Sí, Enviar!"
        cancelButtonText="¡Cancelar!"
      />
      <Modal show={modalMessage} onHide={_ => setModalMessage(false)} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title as="div">Nuevo Mensaje</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormCustom onSubmit={_ => {
            if (!newMessage) {
              Helpers.toast.construct({ response: 'warning', message: 'Ingresar Número', toast })
              return
            }
            Controllers.chat.whatsapp_new_message({
              p_number: '+51' + newMessage,
              p_id_plantilla: 0
            }).then(_ => {
              setModalMessage(false)
              Helpers.toast.construct({ response: 'success', message: 'Mensaje enviado', toast })
            }).catch(_ => {
              setModalMessage(false)
              Helpers.toast.construct({ response: 'success', message: 'Mensaje enviado', toast })
            })
          }} textButton="Enviar mensaje">
            <Select
              options={[
                { label: 'Perú +51', value: 51 }
              ]}
              value={{ label: 'Perú +51', value: 51 }}
              classNameParent="col-4 mb-2"
              text="País"
            />
            <Input
              classNameParent="col-8 mb-2"
              type="text"
              icon="fa fa-phone"
              placeholder="Número"
              text="Número"
              onChange={e => setNewMessage(e.currentTarget.value)}
            />
            <Select
              options={parseInt(idCustomers) === 3 ? [
                { value: 1, label: 'Hola se comunicó con San Charbel en breve lo estaremos contactando.' }
              ] : (parseInt(idCustomers) === 7 ? [
                { value: 1, label: 'Hola Somos de Arteco recibimos tu contacto. Favor escribenos para derivarte con un asesor' }
              ] : [])}
              classNameParent="col-12 mb-2"
              text="Plantilla"
              value={parseInt(idCustomers) === 3 ?
                { value: 1, label: 'Hola se comunicó con San Charbel en breve lo estaremos contactando.' }
                : (parseInt(idCustomers) === 7 ?
                  { value: 1, label: 'Hola Somos de Arteco recibimos tu contacto. Favor escribenos para derivarte con un asesor' }
                  : '')}
            />
          </FormCustom>
        </Modal.Body>
      </Modal>
    </>

    <Modal show={modalTransfer} onHide={_ => setModalTransfer(false)} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title as="div">Transferencia de Chat</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormCustom onSubmit={_ => {
          if (!transferChat.id_customers_contact) {
            Helpers.toast.construct({ response: 'warning', message: 'Debe de seleccionar Agente', toast })
            return
          }

          setLoadSubmitTransfer(true)
          Controllers.chat.chat_transfer({
            p_id_customers_contact: transferChat.id_customers_contact,
            p_id_chat: transferChat.current_chat.id_chat,
            p_message: transferChat.comment,
            p_id_customers_contact_current: transferChat.current_chat.id_seller
          }).then(res => {
            Helpers.toast.construct({ response: 'success', message: 'Transferencia realizada', toast })
            setCurrentChat({})
            loadAll()
            setModalTransfer(false)
          })
        }} textButton="Transferir Chat" loadSubmit={loadSubmitTransfer}>
          <Select
            options={customersContactList.filter(c => parseInt(c.value) !== parseInt(currentChat.id_seller))}
            value={cboCustomerContact()}
            classNameParent="col-12 mb-2"
            text="Agente"
            onChange={e => setTransferChat({
              ...transferChat, id_customers_contact: e.value
            })}
            required
          />
          <TextArea
            required
            classNameParent="col-12 mb-2"
            type="text"
            icon="fa fa-comment"
            placeholder="Comentario adicional"
            text="Comentario adicional"
            value={transferChat.comment}
            onChange={e => setTransferChat({
              ...transferChat, comment: e.currentTarget.value
            })}
            rows={5}
          />
        </FormCustom>
      </Modal.Body>
    </Modal>
  </>
}
