import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import permissions from "./Components/Layout/Resources/Example.json";
import AppLayout from "./Views/Layout/Layout";
import Login from "./Components/Auth/Login/Login";
import NotFound from "./Components/PageError/NotFound/NotFound";
import imageNotFound from "./Resources/not-found.png";
import Helpers from "./Components/Helpers/Helpers";
import Customers from "./Views/Customers/Customers";
import CustomersContact from "./Views/CustomersContact/CustomersContact";
import Project from "./Views/Project/Project";
import Seller from "./Views/Seller/Seller";
import Lead from "./Views/Lead/Lead";
import LeadChat from "./Views/Lead/LeadChat";
import Dashboard from "./Views/Dashboard/Dashboard";
import Comment from "./Views/Comment/Comment";
import Group from "./Views/Group/Group";
import DashboardAdmin from "./Views/Dashboard/DashboardAdmin";
import AlertChatBots from './Views/AlertChatBots/AlertChatBots';
import FeedHistory from "./Views/FeedHistory/FeedHistory";
import MultiAgent from "./Views/MultiAgent/MultiAgent";
import AlertCrm from "./Views/AlertCrm/AlertCrm";
import Profile from "./Views/Profile/Profile";
import { useSelector } from "react-redux";
import File from "./Views/File/File";
import Job from "./Views/Job/Job";
import FormChat from "./Views/MultiAgent/FormChat";
import Quota from "./Views/Quota/Quota";
import Answer from "./Views/Answer/Answer";
import FlowChat from "./Views/FlowChat/FlowChat";
import BotHour from "./Views/BotHour/BotHour";
import Services from "./Views/Services/Services";
import Tag from "./Views/Tag/Tag";
import LeadIg from "./Views/Lead/LeadIg";
import AgentsChats from "./Views/AgentChats/AgentsChats";
import AgentSupervisor from "./Views/AgentChats/AgentSupervisor";

// const xx = {
//   a_href: "alertas-chatbot",
//   description: "Alertas Chatbot \"Solo Master\"",
//   show: 0,
// }

const Routes = ({ type }) => {
  const store = useSelector((store) => store);
  const [isTelcotech, setIsTelcotech] = useState(false);

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    const hostname = window.location.hostname;
    const isTelcotechHost = hostname.includes("telcotech");
    if (
      theme === "2" ||
      window.location.pathname.includes("/login2") ||
      isTelcotechHost
    ) {
      isTelcotechHost && setIsTelcotech(true);
      localStorage.setItem("theme", "2");
      document
        ?.querySelector("#icon-window")
        ?.setAttribute("href", "/assets/customers/telcotec/logo.png");
      document.querySelector("#title-window").innerHTML = "Telcotech";
      return;
    }
    setIsTelcotech(false);
  }, [isTelcotech]);

  const hasPermission = (page) => {
    let permissions = store.session.permissions;

    let has = false;

    permissions?.forEach((tpm) => {
      if (has) {
        return false;
      }

      tpm?.childrens?.forEach((tp) => {
        if (has) {
          return false;
        }

        tp?.childrens?.forEach((tp) => {
          if (has) {
            return false;
          }

          if (tp?.a_href === page) {
            has = true;
          }
        });
      });
    });

    return has;
  };

  if (type === "auth") {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path={Helpers.config.convertUrl({ url: "/" })}>
            <Login
              {...(isTelcotech && {
                isCustom: true,
                customLogo: "/assets/customers/telcotec/logo.png",
                styleLogo: {
                  minWidth: 250,
                },
              })}
            />
          </Route>
          <Route exact path={Helpers.config.convertUrl({ url: "/login" })}>
            <Login
              {...(isTelcotech && {
                isCustom: true,
                customLogo: "/assets/customers/telcotec/logo.png",
                styleLogo: {
                  minWidth: 250,
                },
              })}
            />
          </Route>
          <Route exact path={Helpers.config.convertUrl({ url: "/login2" })}>
            <Login
              isCustom
              customLogo={"/assets/customers/telcotec/logo.png"}
              styleLogo={{
                minWidth: 250,
              }}
            />
          </Route>
          {/* ) : (
            <Route exact path={Helpers.config.convertUrl({ url: "/login" })}>
              <Login
                isCustom
                customLogo={"/assets/customers/telcotec/logo.png"}
                styleLogo={{
                  minWidth: 250,
                }}
              />
            </Route>
          )} */}
          <Route>
            <Redirect to={Helpers.config.convertUrl({ url: "/login" })} />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
  return (
    <BrowserRouter>
      <Switch>
        {hasPermission("clientes") ? (
          <Route path={Helpers.config.convertUrl({ url: "/clientes" })} exact>
            <AppLayout
              title="Listado De Clientes"
              permissions={permissions}
              page="clientes"
            >
              <Customers
                nameSingle="Cliente"
                namePlural="Clientes"
                separator="el"
              />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("usuarios") ? (
          <Route path={Helpers.config.convertUrl({ url: "/usuarios" })} exact>
            <AppLayout
              title="Listado De Usuarios"
              permissions={permissions}
              page="usuarios"
            >
              <CustomersContact
                nameSingle="Usuario"
                namePlural="Usuarios"
                separator="el"
              />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("proyectos") ? (
          <Route path={Helpers.config.convertUrl({ url: "/proyectos" })} exact>
            <AppLayout
              title="Listado De Proyectos"
              permissions={permissions}
              page="proyectos"
            >
              <Project
                nameSingle="Proyecto"
                namePlural="Proyectos"
                separator="el"
              />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("vendedores") ? (
          <Route path={Helpers.config.convertUrl({ url: "/vendedores" })} exact>
            <AppLayout
              title="Listado De Vendedores"
              permissions={permissions}
              page="vendedores"
            >
              <Seller
                nameSingle="Vendedor"
                namePlural="Vendedores"
                separator="el"
              />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("leads-facebook") || hasPermission("dashboard") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/leads-facebook" })}
            exact
          >
            <AppLayout
              title="Leads De Facebook"
              permissions={permissions}
              page="leads-facebook"
            >
              <Lead typeBot="Facebook" />
            </AppLayout>
          </Route>
        ) : (
          ""
        ) }
        {hasPermission("leads-instagram") || hasPermission("dashboard") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/leads-instagram" })}
            exact
          >
            <AppLayout
              title="Leads De Instagram"
              permissions={permissions}
              page="leads-instagram"
            >
              <Lead typeBot="Instagram" />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("leads-whatsapp") || hasPermission("dashboard") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/leads-whatsapp" })}
            exact
          >
            <AppLayout
              title="Leads De Whatsapp"
              permissions={permissions}
              page="leads-whatsapp"
            >
              <Lead typeBot="Whatsapp" />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("leads-web") || hasPermission("dashboard") ? (
          <Route path={Helpers.config.convertUrl({ url: "/leads-web" })} exact>
            <AppLayout
              title="Leads De Web"
              permissions={permissions}
              page="leads-web"
            >
              <Lead typeBot="Web" />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("dashboard") ? (
          <Route path={Helpers.config.convertUrl({ url: "/dashboard" })} exact>
            <AppLayout
              title="Dashboard"
              permissions={permissions}
              page="dashboard"
            >
              {parseInt(store.session.master_user) === 1 ? (
                <DashboardAdmin />
              ) : (
                <Dashboard />
              )}
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("feed") ? (
          <Route path={Helpers.config.convertUrl({ url: "/feed" })} exact>
            <AppLayout title="Feed" permissions={permissions} page="feed">
              <Comment />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("grupos-de-keywords") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/grupos-de-keywords" })}
            exact
          >
            <AppLayout
              title="Grupos de Keywords"
              permissions={permissions}
              page="grupos-de-keywords"
            >
              <Group
                nameSingle="Grupo de Keywords"
                namePlural="Grupos de Keywords"
                separator="el"
              />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("perfiles") ? (
          <Route path={Helpers.config.convertUrl({ url: "/perfiles" })} exact>
            <AppLayout
              title="Perfiles"
              permissions={permissions}
              page="perfiles"
            >
              <Profile
                nameSingle="Perfil"
                namePlural="Perfiles"
                separator="el"
              />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("feed-historial") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/feed-historial" })}
            exact
          >
            <AppLayout
              title="Feed Historial"
              permissions={permissions}
              page="feed-historial"
            >
              <FeedHistory />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("puestos-de-trabajo") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/puestos-de-trabajo" })}
            exact
          >
            <AppLayout
              title="Puestos de Trabajo"
              permissions={permissions}
              page="puestos-de-trabajo"
            >
              <Job
                nameSingle="Puesto de Trabajo"
                namePlural="Puestos de Trabajo"
                separator="el"
              />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("alertas-crm") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/alertas-crm" })}
            exact
          >
            <AppLayout
              title="Alertas CRM"
              permissions={permissions}
              page="alertas-crm"
            >
              <AlertCrm />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("alertas-chatbot") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/alertas-chatbot" })}
            exact
          >
            <AppLayout
              title="Alertas ChatBot"
              permissions={permissions}
              page="alertas-chatbot"
            >
              <AlertChatBots/>
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {/*{hasPermission("chats-agentes") ? (*/}
          <Route
            path={Helpers.config.convertUrl({ url: "/chats-agentes" })}
            exact
          >
            <AppLayout
              title="Chat de Agentes"
              permissions={permissions}
              page="chats-agentes"
            >
              <AgentsChats />
            </AppLayout>
          </Route>
        {//) : (
          //  ""
          //)
        }
        {/*{hasPermission("configuracion-agentes") ? (*/}
        {/* <Route
            path={Helpers.config.convertUrl({ url: "/configuracion-agentes" })}
            exact
          >
            <AppLayout
              title="Configuracion de agentes"
              permissions={permissions}
              page="configuracion-agentes"
            >
              <AgentSupervisor />
            </AppLayout>
          </Route> */}
        {//) : (
          //  ""
          //)
        }
        {hasPermission("formulario") ? (
          <Route path={Helpers.config.convertUrl({ url: "/formulario" })} exact>
            <AppLayout
              title="Formulario"
              permissions={permissions}
              page="formulario"
            >
              <FormChat />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("leads-multiagente") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/leads-multiagente" })}
            exact
          >
            <AppLayout
              title="Leads Multiagente"
              permissions={permissions}
              page="leads-multiagente"
            >
              <LeadChat />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("quota-whatsapp") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/quota-whatsapp" })}
            exact
          >
            <AppLayout
              title="Cuota Multiagente"
              permissions={permissions}
              page="quota-whatsapp"
            >
              <Quota
                nameSingle="Cuota Multiagente"
                namePlural="Cuota Multiagente"
                separator="la"
              />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("archivos") ? (
          <Route path={Helpers.config.convertUrl({ url: "/archivos" })} exact>
            <AppLayout
              title="Archivos"
              permissions={permissions}
              page="archivos"
            >
              <File nameSingle="Archivo" namePlural="Archivos" separator="el" />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("chatbot-horario") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/chatbot-horario" })}
            exact
          >
            <AppLayout
              title="Chatbot Horario"
              permissions={permissions}
              page="chatbot-horario"
            >
              <BotHour
                nameSingle="Chatbot Horario"
                namePlural="Chatbot Horario"
                separator="el"
              />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("dashboard") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/services" })}
            exact
          >
            <AppLayout
              title="Servicios"
              permissions={permissions}
              page="services"
            >
              <Services />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("respuestas-automaticas") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/respuestas-automaticas" })}
            exact
          >
            <AppLayout
              title="Archivos"
              permissions={permissions}
              page="respuestas-automaticas"
            >
              <Answer />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("flujo-chatbot") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/flujo-chatbot" })}
            exact
          >
            <AppLayout
              title="Flujo Chatbot"
              permissions={permissions}
              page="flujo-chatbot"
            >
              <FlowChat />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("etiquetas") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/etiquetas" })}
            exact
          >
            <AppLayout
              title="Etiquetas"
              permissions={permissions}
              page="etiquetas"
            >
              <Tag
                nameSingle="Etiqueta"
                namePlural="Etiquetas"
                separator="la"
              />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("leads-instagram") || hasPermission("dashboard") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/leads-instagram" })}
            exact
          >
            <AppLayout
              title="Leads De Instagram"
              permissions={permissions}
              page="leads-instagram"
            >
              <LeadIg typeBot={'Instagram'} />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        {hasPermission("leads-whatsapp-servicios") || hasPermission("dashboard") ? (
          <Route
            path={Helpers.config.convertUrl({ url: "/leads-whatsapp-servicios" })}
            exact
          >
            <AppLayout
              title="Leads De Whatsapp"
              permissions={permissions}
              page="leads-whatsapp-servicios"
            >
              <LeadIg typeBot={'Whatsapp'} />
            </AppLayout>
          </Route>
        ) : (
          ""
        )}
        <Route path={Helpers.config.convertUrl({ url: "/multiagente" })} exact>
          <AppLayout
            title="Multiagente"
            permissions={permissions}
            page="multiagente"
          >
            <MultiAgent />
          </AppLayout>
        </Route>
        <Route path={Helpers.config.convertUrl({ url: "/login" })} exact>
          <Redirect
            to={Helpers.config.convertUrl({
              url: "/" + store.session.main_profile,
            })}
          />
        </Route>
        <Route path={Helpers.config.convertUrl({ url: "/login2" })} exact>
          <Redirect
            to={Helpers.config.convertUrl({
              url: "/" + store.session.main_profile,
            })}
          />
        </Route>
        <Route path={Helpers.config.convertUrl({ url: "/" })} exact>
          <Redirect
            to={Helpers.config.convertUrl({
              url: "/" + store.session.main_profile,
            })}
          />
        </Route>
        <Route>
          <AppLayout
            title="No existe la página"
            permissions={permissions}
            showBread={false}
          >
            <NotFound backUrl="/" imageNotFound={imageNotFound} />
          </AppLayout>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

Routes.defaultProps = {
  type: "session",
};

export default Routes;
