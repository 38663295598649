import React, { useState, useCallback } from "react";
import { Input, CheckBox } from "../../Form/Form";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import Box from "./Styled/Box";
import logoCompany from "../../../Resources/logo.png";
import Helpers from "../../Helpers/Helpers";
import Controllers from "../../../Api/Controllers";
import { toast } from "react-toastify";

const Login = ({ customLogo, styleLogo, isCustom }) => {
  const [session, setSession] = useState([]);

  const dataSubmit = useCallback(() => {
    return {
      p_username: session.username,
      p_password: session.password,
    };
  }, [session]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let $this = e?.target;

    if (!$this?.checkValidity()) {
      $this?.classList?.add("was-validated");
    } else {
      $this?.classList?.remove("was-validated");

      let button = $this?.querySelector("button");
      let buttonHTML = button?.innerHTML;
      button.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>';

      Controllers.users[isCustom ? "get_login_telcotech" : "get_login"](
        dataSubmit()
      )
        .then((res) => {
          Helpers.toast.construct({ ...res, toast });
          if (res.response === "success") {
            if (
              window?.location?.pathname.includes("login2") ||
              window?.location?.hostname.includes("telcotech")
            ) {
              localStorage.setItem("theme", 2);
            } else {
              localStorage.setItem("theme", 1);
            }

            Helpers.localStorage.setSession({
              jwt: res.data.jwt,
            });
            window.location.href = Helpers.config.folder_path;
          } else {
            button.innerHTML = buttonHTML;
          }
        })
        .catch((req) => {
          Helpers.promise.catch({ req, toast });
          button.innerHTML = buttonHTML;
        });
    }
  };

  return (
    <Box>
      <Box.Container>
        <Box.Content className="bg-white form-row rounded">
          <div className="col-12 text-center mb-4">
            <Box.Logo
              src={customLogo ? customLogo : logoCompany}
              className="box-bg-content-logo"
              alt=""
              style={styleLogo}
            />
          </div>

          <form
            onSubmit={handleSubmit}
            autoComplete="off"
            className="col-12 needs-validation"
            noValidate
          >
            <div className="form-row">
              <Input
                text="Nombre de usuario"
                placeholder="Nombre de usuario"
                icon="fa fa-user"
                classNameParent="col-12 mb-2"
                required={true}
                defaultValue={session.username}
                onChange={(e) =>
                  setSession({ ...session, username: e.currentTarget.value })
                }
              />
              <Input
                type="password"
                text="Contraseña"
                placeholder="Contraseña"
                icon="fa fa-lock"
                classNameParent="col-12 mb-2"
                required={true}
                defaultValue={session.password}
                onChange={(e) =>
                  setSession({ ...session, password: e.currentTarget.value })
                }
              />
              <CheckBox
                text="Recordarme"
                defaultChecked={false}
                id="remember_me"
              />
              <div className="col-12 mt-2">
                <Button type="submit" variant="primary" block>
                  <i className="fa fa-check mr-2"></i> Iniciar Sesión
                </Button>
              </div>
            </div>
          </form>
        </Box.Content>
      </Box.Container>
    </Box>
  );
};

Login.defaultProps = {
  logoCompany: "",
  onSubmit: () => {},
  customLogo: "",
  styleLogo: {},
  isCustom: false,
};

Login.propTypes = {
  logoCompany: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default Login;
