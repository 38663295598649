import React, { useEffect, useState } from "react";
import { Card, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";

import Helpers from "../Helpers/Helpers";
import Controllers from "../../Api/Controllers"
import { flowChatRequests } from "../../Api/flowChatRequest";

import {
  FormCustom,
  TextArea,
  CheckBox,
  Input,
  Select,
} from "../Form/Form";
import SelectTime from "./SelectTime";

export default function ChatBotFlow(props) {
  const [row, setRow] = useState(undefined);
  const [loadSubmit, setLoadSubmit] = useState(false);

  const { item, listAgentes, rowWelcome } = props;

  const onChangMessageWelcome = async (enabled) => {
    try {
      const form = {
        p_message: rowWelcome.message,                
        p_frequency: parseInt(rowWelcome.frequency),               
        p_time: parseInt(rowWelcome.time),
        p_id_answer: rowWelcome.id_answer,
        p_type: rowWelcome.type,
        p_enabled: enabled,
        p_agents: rowWelcome.agents,
        p_transfer: rowWelcome?.transfer,
      }
      const res = await Controllers.answer.answer_insert_update(form);
      Helpers.toast.construct({ ...res, toast })
      if (res.response === 'success') {
        if (parseInt(enabled) === 1) {
          Helpers.toast.construct({
            toast,
            response: "info",
            message: `Se a habilito Mensaje de Bienvenida de ${rowWelcome.type}`,
          });
        } else {
          Helpers.toast.construct({
            toast,
            response: "info",
            message: `Se a deshabilitado Mensaje de Bienvenida de ${rowWelcome.type}`,
          });
        }
      }
    } catch (req) {
      Helpers.promise.catch({ req, toast })
    } finally {
      setLoadSubmit(false)
    }
  }

  useEffect(() => {
    setRow({
      p_type: item.type,
      p_options: item.options,
      p_message: item.text_init,
      p_flow_chat: item.id_flow_chat,
      p_enabled: item?.enabled || 0,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = ({ data }) => {
    if (row?.p_options.length === 0) {
      Helpers.toast.construct({
        toast,
        response: "warning",
        message: "Agregar opciones",
      });
      return;
    }

    let agentsSelected = true;

    row?.p_options.forEach((opt) => {
      if (agentsSelected && opt.agentes.length === 0) {
        agentsSelected = false;
      }
    });

    if (!agentsSelected) {
      Helpers.toast.construct({
        toast,
        response: "warning",
        message: "Completar todos los campos de las opciones",
      });
      return;
    }

    setLoadSubmit(true);
    Controllers.flow_chat.flow_chat_insert_update(row)
      .then((res) => {
        Helpers.toast.construct({ ...res, toast });
        
        if (res.response === "success") {
          if (rowWelcome.id_answer) {
            onChangMessageWelcome(row.p_enabled === 1 ? 0 : 1)
          }
        }
      })
      .catch((req) => Helpers.promise.catch({ req, toast }))
      .finally(() => {
        setLoadSubmit(false);
      });
  };

  const deleteAgent = (id_agent, id_opt) => {
    //flowChatRequests.deleteAgent(id_agent, id_opt).then((res) => {
    //  Helpers.toast.construct({ ...res, toast });
    //  if (res.response === "success") {
        setRow({
          ...row,
          p_options: row?.p_options?.map((opt) => {
            if (opt.id_opt === id_opt) {
              opt.agentes = opt.agentes.filter(
                (ag) => ag.id_agent !== id_agent
              );
            }
            return opt;
          }),
        });
      //}
    //  window.location.reload();
    //});
  };

  const deleteOpt = (id_opt) => {
    //flowChatRequests.deleteOpt(id_opt).then((res) => {
    //  Helpers.toast.construct({ ...res, toast });
    //  if (res.response === "success") {
        setRow({
          ...row,
          p_options: row?.p_options.filter(
            (opt) => opt.id_option !== id_opt
          ),
        });
    //  }
    //  window.location.reload();
    //});
  };

  const onChangeTransfer = (id_opt, name, value) => {
    setRow({
      ...row,
      p_options: row?.p_options?.map((opt) => {
        if (opt.id_opt === id_opt) {
          opt.transfer = {
            ...opt.transfer,
            [name]: value
          };
        }
        return opt;
      }),
    });
  };

  return (
    <Card>
      <Card.Header className="justify-content-between">
        <span>{`Flujo ChatBot ${row?.p_type || ''}`}</span>
      </Card.Header>
      <Card.Body>
        <FormCustom
          dataSubmit={row}
          onSubmit={onSubmit}
          loadSubmit={loadSubmit}
        >
          <TextArea
            text="Mensaje Inicial"
            placeholder="Mensaje Inicial"
            classNameParent="col-12 mb-2"
            invalid="El campo es obligatorio."
            defaultValue={row?.p_message}
            onChange={(e) =>
              setRow({
                ...row,
                p_message: e.currentTarget.value,
              })
            }
            rows="10"
            required
          />

          <div className="col-12 mt-2 d-flex justify-content-between align-items-center">
            <Row>
              <CheckBox
                text="Activar"
                classNameParent="col-4"
                id={row?.p_flow_chat}
                checked={parseInt(row?.p_enabled) === 1}
                onChange={() => setRow({ ...row, p_enabled: row?.p_enabled === 1 ? 0 : 1, })}
              />
            </Row>
            <Button
              variant="info"
              size="sm"
              onClick={(_) => {
                setRow({
                  ...row,
                  p_options: [
                    ...row?.p_options,
                    {
                      text: "",
                      agentes: [],
                      transfer: {
                        minutes: 0,
                        hours: 0,
                        enabled: 0
                      }
                    },
                  ],
                });
              }}
            >
              <i className="fa fa-plus mr-2"></i>Agregar opción
            </Button>
          </div>

          <div className="col-12">
            <hr />
            {React.Children.toArray(
              row?.p_options?.map((opt, i) => {
                return (
                  <>
                    <div key={i}>
                      <div className="d-flex flex-row justify-content-between align-content-center align-items-center">
                        <Input
                          placeholder="Opción"
                          classNameParent="w-100"
                          text={`Opción #${i + 1}`}
                          required
                          value={opt.text}
                          onChange={(e) => {
                            setRow({
                              ...row,
                              p_options: row?.p_options.map(
                                (opt2, i2) => {
                                  if (i === i2) {
                                    opt2.text = e.currentTarget.value;
                                  }
                                  return opt2;
                                }
                              ),
                            });
                          }}
                        />
                        <Button
                          variant="danger"
                          size="sm"
                          className="ml-2"
                          onClick={(_) => {
                            deleteOpt(opt.id_opt);
                          }}
                        >
                          <i className="fa fa-trash"></i>
                        </Button>
                      </div>
                      <div>
                        <Row>
                          <CheckBox
                            text="Tiempo de transferencia"
                            classNameParent="col-12"
                            id={`1${row?.p_flow_chat}`}
                            checked={parseInt(opt?.transfer.enabled) === 1}
                            onChange={() => onChangeTransfer(opt.id_opt, 'enabled', opt?.transfer.enabled === 1 ? 0 : 1)}
                          />
                        </Row>
                        {opt.transfer.enabled === 1 && (
                          <SelectTime
                            value={opt.transfer}
                            onClickMinutes={value => onChangeTransfer(opt.id_opt, 'minutes', value)}
                            onClickHours={value => onChangeTransfer(opt.id_opt, 'hours', value)}
                          />
                        )}
                      </div>

                      <Select
                        options={listAgentes.map((agt) => {
                          return {
                            label: agt.name_contact,
                            value: agt.id_customers_contact,
                            isDisabled: opt.agentes.some((a) => {
                              return (
                                parseInt(a.id) ===
                                parseInt(agt.id_customers_contact)
                              );
                            }),
                          };
                        })}
                        onChange={(e) => {
                          setRow({
                            ...row,
                            p_options: row?.p_options?.map(
                              (opt2, i2) => {
                                if (i === i2) {
                                  opt2.agenteSelected = {
                                    name: e.label,
                                    id: e.value,
                                  };
                                }
                                return opt2;
                              }
                            ),
                          });
                        }}
                        classNameParent="w-100 mb-2"
                        text="Agentes"
                        append={[
                          <Button
                            variant="primary"
                            onClick={(_) => {
                              if (!opt?.agenteSelected?.id) {
                                return;
                              }
                              setRow({
                                ...row,
                                p_options: row?.p_options.map(
                                  (opt2, i2) => {
                                    if (i === i2) {
                                      opt2.agentes = [
                                        ...opt2.agentes,
                                        opt.agenteSelected,
                                      ];
                                      opt.agenteSelected = {};
                                    }
                                    return opt2;
                                  }
                                ),
                              });
                            }}
                          >
                            <i className="fa fa-plus"></i>
                          </Button>,
                        ]}
                      />
                      <strong className="text-primary">
                        Agentes seleccionados
                      </strong>
                      {React.Children.toArray(
                        opt.agentes.map((a, j) => {
                          return (
                            <div className="flex-row d-flex justify-content-between p-2 align-items-center">
                              <strong>
                                #{j + 1}. {a.name}
                              </strong>
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={(_) => {
                                  deleteAgent(a.id, opt.id_opt);
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                            </div>
                          );
                        })
                      )}
                    </div>
                    <hr />
                  </>
                );
              })
            )}
          </div>
        </FormCustom>
      </Card.Body>
    </Card>
  )
}
