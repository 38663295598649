import React, { useState } from "react";
import { servicesRequests } from "../../Api/servicesRequests";

function AddService({setServices}) {
  const [name, setName] = useState("");
  const [keywords, setKeywords] = useState("");

  const addService = (e) => {
    e.preventDefault();
    servicesRequests.post({
      service: name,
      keywords: keywords,
    }).then((response) => {
      setServices(pv => [...pv, response]);
    });

    setName("");
    setKeywords("");
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h3 className="font-weight-bold text-center">Añadir un servicio</h3>
          <form onSubmit={(e) => addService(e)}>
            <div className="form-group">
              <label htmlFor="name">Nombre</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Nombre del servicio"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="keywords">Keywords</label>
              <input
                type="text"
                className="form-control"
                id="keywords"
                placeholder="Keywords del servicio"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
              />
            </div>
            <input
              name="add"
              id="add"
              class="btn btn-success btn-block"
              type="submit"
              value="Agregar"
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default AddService;
