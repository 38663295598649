import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/esm/Button'
import Controllers from '../../Api/Controllers'
import { Input, TextArea } from '../../Components/Form/Form'

const FormUpdate = ({ message, idChat, onCallback }) => {
    const [value, setValue] = useState(message.value)
    useEffect(() => {
        setValue(message.value)
    }, [ idChat, message ])

    if (message.type === 'EMAIL' || message.type === 'TEXT') {
        return <Input
            type={message.type === 'EMAIL' ? 'email' : 'text'}
            classNameParent="w-100 mb-2"
            text={message.field_name}
            placeholder={message.field_name}
            required={parseInt(message.required) === 1}
            maxLength={message.max_length}
            value={message.value}
            onChange={e => onCallback({ currentValue: e.currentTarget.value })}
            append={[
                <Button variant="outline-primary" onClick={e => {
                    let $this = e.currentTarget
                    let buttonHTML = $this.innerHTML
                    $this.innerHTML = '<i class="fa fa-circle-notch fa spin"></i>'
                    Controllers.chat.chat_form_update({
                        p_id_chat: idChat,
                        p_key_name: message.key_name,
                        p_value: message.value
                    }).then(_ => {
                        $this.innerHTML = buttonHTML
                    })
                }}>
                    <i className="far fa-save"></i>
                </Button>
            ]}
        />
    } else if (message.type === 'TEXTAREA') {
        debugger
        return <TextArea
            type={message.type === 'EMAIL' ? 'email' : 'text'}
            classNameParent="w-100 mb-2"
            text={message.field_name}
            placeholder={message.field_name}
            required={parseInt(message.required) === 1}
            maxLength={message.max_length}
            value={message.value}
            onChange={e => onCallback({ currentValue: e.currentTarget.value })}
            append={[
                <Button variant="outline-primary" onClick={e => {
                    let $this = e.currentTarget
                    let buttonHTML = $this.innerHTML
                    $this.innerHTML = '<i class="fa fa-circle-notch fa spin"></i>'
                    Controllers.chat.chat_form_update({
                        p_id_chat: idChat,
                        p_key_name: message.key_name,
                        p_value: message.value
                    }).then(_ => {
                        $this.innerHTML = buttonHTML
                    })
                }}>
                    <i className="far fa-save"></i>
                </Button>
            ]}
        />
    } else {
        return <div className="w-100 mb-2">
            <small className="text-muted">{message.field_name}</small>
            <div className={'input-group input-group-' + 'sm'}>
                <select className="form-control" value={message.value} onChange={e => {
                    onCallback({ currentValue: e.currentTarget.value })
                    Controllers.chat.chat_form_update({
                        p_id_chat: idChat,
                        p_key_name: message.key_name,
                        p_value: e.currentTarget.value
                    })
                }}>
                    <option value="">Seleccionar...</option>
                    {React.Children.toArray(message.list_values.map(l => {
                        if (l?.label) {
                            return <option value={l.value}>{l.label}</option>
                        }

                        return <option value={l}>{l}</option>
                    }))}
                </select>
            </div>
        </div>
    }
}

export default FormUpdate