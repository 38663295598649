import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { RectShape, TextBlock, TextRow } from 'react-placeholder/lib/placeholders';
// import Card from "react-bootstrap/Card";
// import Button from "react-bootstrap/Button";
import Controllers from "../../Api/Controllers";
// import { toast } from "react-toastify";
// import Helpers from "../../Components/Helpers/Helpers";
// import {
//   FormCustom,
//   TextArea,
//   // CheckBox,
//   Input,
//   Select,
// } from "../../Components/Form/Form";
// import { ReactReduxContext } from "react-redux";
// import { flowChatRequests } from "../../Api/flowChatRequest";
import ChatBotFlow from "../../Components/common/ChatBotFlow";

const FlowChat = (_) => {
  const [ rows, setRows ] = useState([]);
  const [rowsWelcome, setRowsWelcome] = useState([])
  const [load, setLoad] = useState(true);
  // const [rowWhatsapp, setRowWhatsapp] = useState({
  //   p_message: "",
  //   p_options: [],
  // });
  // const [loadSubmitWhatsapp, setLoadSubmitWhatsapp] = useState(false);
  const [listAgentes, setListAgentes] = useState([]);

  useEffect((_) => {
    setLoad(true);
    Promise.all([
      // Controllers.flow_chat.get_flow_chat(),
      Controllers.flow_chat.get_flow_chat2(),
      Controllers.customers_contact.get_customers_contact(),
      Controllers.answer.get_answer(),
    ]).then((res) => {
      const rowsNew = [];
      const data = res[0].data;
      data.forEach((item) => {
        if (item?.WEB) {
          rowsNew.push({...item.WEB, type: 'Web'})
        } else if (item?.WHATSAPP) {
          rowsNew.push({...item.WHATSAPP, type: 'Whatsapp'})
        } else if (item?.FACEBOOK) {
          rowsNew.push({...item.FACEBOOK, type: 'Facebook'})
        } else if (item?.INSTAGRAM) {
          rowsNew.push({...item.INSTAGRAM, type: 'Instagram'})
        }
      });
      setRows(rowsNew)

      // setRows([
      //   { ...res[0].data, type: 'Whatsapp', enabled: 0 },
      //   { ...res[0].data, type: 'Facebook', enabled: 0 },
      //   { ...res[0].data, type: 'Instagram', enabled: 0 },
      // ]);
      setListAgentes(res[1].data);
      setRowsWelcome(res[2].data)
      // setRowWhatsapp({
      //   p_message: res[0].data.text_init,
      //   p_options: res[0].data.options,
      //   p_flow_chat: res[0].data.flow_chat,
      // });
    }).finally(() => setLoad(false))
  }, []);

  // const onSubmitWhatsapp = ({ data }) => {
  //   if (rowWhatsapp.p_options.length === 0) {
  //     Helpers.toast.construct({
  //       toast,
  //       response: "warning",
  //       message: "Agregar opciones",
  //     });
  //     return;
  //   }

  //   let agentsSelected = true;

  //   rowWhatsapp.p_options.forEach((opt) => {
  //     if (agentsSelected && opt.agentes.length === 0) {
  //       agentsSelected = false;
  //     }
  //   });

  //   if (!agentsSelected) {
  //     Helpers.toast.construct({
  //       toast,
  //       response: "warning",
  //       message: "Completar todos los campos de las opciones",
  //     });
  //     return;
  //   }

  //   setLoadSubmitWhatsapp(true);
  //   Controllers.flow_chat
  //     .flow_chat_insert_update(rowWhatsapp)
  //     .then((res) => {
  //       Helpers.toast.construct({ ...res, toast });
  //       if (res.response === "success") {
  //       }
  //     })
  //     .catch((req) => Helpers.promise.catch({ req, toast }))
  //     .finally(() => {
  //       setLoadSubmitWhatsapp(false);
  //     });
  // };

  // const deleteAgent = (id_agent, id_opt) => {
  //   flowChatRequests.deleteAgent(id_agent, id_opt).then((res) => {
  //     Helpers.toast.construct({ ...res, toast });
  //     if (res.response === "success") {
  //       setRowWhatsapp({
  //         ...rowWhatsapp,
  //         p_options: rowWhatsapp.p_options.map((opt) => {
  //           if (opt.id_option === id_opt) {
  //             opt.agentes = opt.agentes.filter(
  //               (ag) => ag.id_agent !== id_agent
  //             );
  //           }
  //           return opt;
  //         }),
  //       });
  //     }
  //     window.location.reload();
  //   });
  // };

  // const deleteOpt = (id_opt) => {
  //   flowChatRequests.deleteOpt(id_opt).then((res) => {
  //     Helpers.toast.construct({ ...res, toast });
  //     if (res.response === "success") {
  //       setRowWhatsapp({
  //         ...rowWhatsapp,
  //         p_options: rowWhatsapp.p_options.filter(
  //           (opt) => opt.id_option !== id_opt
  //         ),
  //       });
  //     }
  //     window.location.reload();
  //   });
  // };

  return (
    <div className="form-row">
      {load ? (
        <>
          <div className="col-md-6 mb-2">
            <Card style={{ padding: '10px 20px' }}>
              <TextRow color='#E0E0E0' style={{ width: 150, height: 15, borderRadius: 10, }} />
              <RectShape color='#E0E0E0' style={{ width: '100%', margin: '40px 0 20px', height: 200, borderRadius: 10, }} />
              <TextBlock color='#E0E0E0' rows={4} />
            </Card>
          </div>
          <div className="col-md-6 mb-2">
            <Card style={{ padding: '10px 20px' }}>
              <TextRow color='#E0E0E0' style={{ width: 150, height: 15, borderRadius: 10, }} />
              <RectShape color='#E0E0E0' style={{ width: '100%', margin: '40px 0 20px', height: 200, borderRadius: 10, }} />
              <TextBlock color='#E0E0E0' rows={4} />
            </Card>
          </div>
        </>
      ) : rows.map(item => (
        <div className="col-md-6 mb-2">
          <ChatBotFlow
            item={item}
            key={ item.type }
            rowWelcome={rowsWelcome.find(it =>
              it.type.toLowerCase() === item.type.toLowerCase()
            )}
            listAgentes={listAgentes}
          />
        </div>
      ))}
        {/* <Card>
          <Card.Header className="justify-content-between">
            <span>Flujo ChatBot</span>
          </Card.Header>
          <Card.Body>
            <FormCustom
              dataSubmit={rowWhatsapp}
              onSubmit={onSubmitWhatsapp}
              loadSubmit={loadSubmitWhatsapp}
            >
              <TextArea
                text="Mensaje Inicial"
                placeholder="Mensaje Inicial"
                classNameParent="col-12 mb-2"
                invalid="El campo es obligatorio."
                defaultValue={rowWhatsapp.p_message}
                onChange={(e) =>
                  setRowWhatsapp({
                    ...rowWhatsapp,
                    p_message: e.currentTarget.value,
                  })
                }
                rows="10"
                required
              />

              <div className="col-12 mt-2">
                <Button
                  variant="info"
                  size="sm"
                  onClick={(_) => {
                    setRowWhatsapp({
                      ...rowWhatsapp,
                      p_options: [
                        ...rowWhatsapp.p_options,
                        {
                          text: "",
                          agentes: [],
                        },
                      ],
                    });
                  }}
                >
                  <i className="fa fa-plus mr-2"></i>Agregar opción
                </Button>
              </div>

              <div className="col-12">
                <hr />
                {React.Children.toArray(
                  rowWhatsapp.p_options.map((opt, i) => {
                    return (
                      <>
                        <div>
                          <div className="d-flex flex-row justify-content-between align-content-center align-items-center">
                            <Input
                              placeholder="Opción"
                              classNameParent="w-100 mb-2"
                              text={`Opción #${i + 1}`}
                              required
                              value={opt.text}
                              onChange={(e) => {
                                setRowWhatsapp({
                                  ...rowWhatsapp,
                                  p_options: rowWhatsapp.p_options.map(
                                    (opt2, i2) => {
                                      if (i === i2) {
                                        opt2.text = e.currentTarget.value;
                                      }
                                      return opt2;
                                    }
                                  ),
                                });
                              }}
                            />
                            <Button
                              variant="danger"
                              size="sm"
                              className="ml-2"
                              onClick={(_) => {
                                deleteOpt(opt.id_opt);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </div>
                          <Select
                            options={listAgentes.map((agt) => {
                              return {
                                label: agt.name_contact,
                                value: agt.id_customers_contact,
                                isDisabled: opt.agentes.some((a) => {
                                  return (
                                    parseInt(a.id) ===
                                    parseInt(agt.id_customers_contact)
                                  );
                                }),
                              };
                            })}
                            onChange={(e) => {
                              setRowWhatsapp({
                                ...rowWhatsapp,
                                p_options: rowWhatsapp.p_options.map(
                                  (opt2, i2) => {
                                    if (i === i2) {
                                      opt2.agenteSelected = {
                                        name: e.label,
                                        id: e.value,
                                      };
                                    }
                                    return opt2;
                                  }
                                ),
                              });
                            }}
                            classNameParent="w-100 mb-2"
                            text="Agentes"
                            append={[
                              <Button
                                variant="primary"
                                onClick={(_) => {
                                  if (!opt?.agenteSelected?.id) {
                                    return;
                                  }
                                  setRowWhatsapp({
                                    ...rowWhatsapp,
                                    p_options: rowWhatsapp.p_options.map(
                                      (opt2, i2) => {
                                        if (i === i2) {
                                          opt2.agentes = [
                                            ...opt2.agentes,
                                            opt.agenteSelected,
                                          ];
                                          opt.agenteSelected = {};
                                        }
                                        return opt2;
                                      }
                                    ),
                                  });
                                }}
                              >
                                <i className="fa fa-plus"></i>
                              </Button>,
                            ]}
                          />
                          <strong className="text-primary">
                            Agentes seleccionados
                          </strong>
                          {React.Children.toArray(
                            opt.agentes.map((a, j) => {
                              return (
                                <div className="flex-row d-flex justify-content-between p-2 align-items-center">
                                  <strong>
                                    #{j + 1}. {a.name}
                                  </strong>
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={(_) => {
                                      deleteAgent(a.id, opt.id_opt);
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Button>
                                </div>
                              );
                            })
                          )}
                        </div>
                        <hr />
                      </>
                    );
                  })
                )}
              </div>
            </FormCustom>
          </Card.Body>
        </Card> */}
    </div>
  );
};

export default FlowChat;
