const data = {
  "chats": [
    {
      "id_chat": 7941,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "rRTq6mks9r5F0Y7plmhl",
      "date_created": "2022-05-12 12:46:26",
      "message": "Quisiera.cotizar un proyecto",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 0,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7941,
          "message": "Quisiera.cotizar un proyecto",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "12/05/2022",
          "hour_message": "12:46 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-12 12:46:26",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7939,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "2Ya5mIqQA8USpZ3TGN48",
      "date_created": "2022-05-12 12:24:58",
      "message": "Quiero agendar una visita para hoy",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 0,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7939,
          "message": "Quiero agendar una visita para hoy",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "12/05/2022",
          "hour_message": "12:24 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-12 12:24:58",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7906,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "rC8NfGErSNcWD2diXkNl",
      "date_created": "2022-05-11 16:33:38",
      "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7906,
          "message": "Buscon un depa de 3 habitaciones",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "10/05/2022",
          "hour_message": "19:08 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 19:08:44",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7906,
          "message": "Ofertas de departamentos de 3 habitaciones, minimo 2 baÒos y cochera, en san miguel, pueblo libre o magdalena",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "10/05/2022",
          "hour_message": "19:09 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 19:09:46",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7906,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "11/05/2022",
          "hour_message": "16:33 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-11 16:33:38",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7897,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "vekar5Xew48ZeAL25DC0",
      "date_created": "2022-05-10 14:42:28",
      "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7897,
          "message": "hola se llevara acabo una expo feria",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "10/05/2022",
          "hour_message": "13:25 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 13:25:35",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7897,
          "message": "queria hacerte llegar la informacion",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "10/05/2022",
          "hour_message": "13:25 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 13:25:46",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7897,
          "message": "si tienes algun correo puedas proposionar",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "10/05/2022",
          "hour_message": "13:25 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 13:25:56",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7897,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "10/05/2022",
          "hour_message": "14:42 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 14:42:28",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7890,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "kgZ402Je9HXZOYcAwnQl",
      "date_created": "2022-05-10 11:37:28",
      "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7890,
          "message": "Buenos dÌas ingresÈ mis datos ,y como saber  el resultado",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "10/05/2022",
          "hour_message": "10:20 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 10:20:48",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7890,
          "message": "El dÌa s·bado tambiÈn ingresÈ. Pero no sÈ m·s informaciÛn ,,q",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "10/05/2022",
          "hour_message": "10:22 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 10:22:21",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7890,
          "message": "Lo agradecerÌa muchÌsimo la respuesta o m·s informaciÛn gracias gracias",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "10/05/2022",
          "hour_message": "10:22 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 10:22:50",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7890,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "10/05/2022",
          "hour_message": "11:37 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 11:37:28",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7885,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "UwB2oxVkys0yE9XOspP2",
      "date_created": "2022-05-10 10:04:07",
      "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7885,
          "message": "Buenas tardes,",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "09/05/2022",
          "hour_message": "17:58 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-09 17:58:58",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7885,
          "message": "quiero saber el precio aproximado de un departamento de 3 o 4 ambientes en el proyecto Join de Jes˙s MarÌa",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "09/05/2022",
          "hour_message": "17:59 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-09 17:59:23",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7885,
          "message": "(si fuera posible, informaciÛn al correo: rogerlopez@gmail.com )",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "09/05/2022",
          "hour_message": "17:59 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-09 17:59:40",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7885,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "10/05/2022",
          "hour_message": "10:04 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 10:04:07",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7881,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "B5PbDV2ygpPn8HwA8T5I",
      "date_created": "2022-05-10 10:03:35",
      "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7881,
          "message": "hola",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "09/05/2022",
          "hour_message": "16:04 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-09 16:04:47",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7881,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "10/05/2022",
          "hour_message": "10:03 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 10:03:35",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7873,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "XpZ8wLmr9Nr8O1y841Jm",
      "date_created": "2022-05-09 15:37:05",
      "message": "Proyecto We Live",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 2,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7873,
          "message": "øCu·ndo se entrega el proyecto ?",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "09/05/2022",
          "hour_message": "13:20 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-09 13:20:10",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7873,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "09/05/2022",
          "hour_message": "13:20 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-09 13:20:47",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7873,
          "message": "Miguel Alcal·",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "09/05/2022",
          "hour_message": "15:35 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-09 15:35:28",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7873,
          "message": "miguel.alcala.rios@gmail.com",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "09/05/2022",
          "hour_message": "15:35 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-09 15:35:35",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7873,
          "message": "996574075",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "09/05/2022",
          "hour_message": "15:35 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-09 15:35:46",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7873,
          "message": "San Charbel",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "09/05/2022",
          "hour_message": "15:36 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-09 15:36:01",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7873,
          "message": "2 ambientes",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "09/05/2022",
          "hour_message": "15:36 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-09 15:36:14",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7873,
          "message": "Proyecto We Live",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "09/05/2022",
          "hour_message": "15:37 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-09 15:37:05",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": "miguel.alcala.rios@gmail.com"
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": "Miguel"
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": "996574075"
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": "Alcal·"
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": "2"
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": "49"
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7869,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "z22d2xq5OrtQWHpY0iQp",
      "date_created": "2022-05-09 13:15:59",
      "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7869,
          "message": "Hola podrÌa contactarme con un asesor",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "09/05/2022",
          "hour_message": "12:00 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-09 12:00:27",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7869,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "09/05/2022",
          "hour_message": "13:15 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-09 13:15:59",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7860,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "JQWZsLTsY9nkI1vdhorD",
      "date_created": "2022-05-10 10:52:18",
      "message": "Y especialmente interesado en el Dpto. 106",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 2,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7860,
          "message": "Buenas tardes, estas interesado en un departamento de 3 dormitorios del proyecto We live",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "08/05/2022",
          "hour_message": "16:22 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-08 16:22:06",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7860,
          "message": "Especificamente en el departamento 106",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "08/05/2022",
          "hour_message": "16:23 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-08 16:23:00",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7860,
          "message": "Me puede brindar informacion de dicho departamento,",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "08/05/2022",
          "hour_message": "16:23 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-08 16:23:28",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7860,
          "message": "Perdon, mi nombre es Jose Pardo. Agracedere la informacion solicitada",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "08/05/2022",
          "hour_message": "16:24 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-08 16:24:29",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7860,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "09/05/2022",
          "hour_message": "09:18 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-09 09:18:19",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7860,
          "message": "Buenos dias.",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "10/05/2022",
          "hour_message": "10:50 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 10:50:40",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7860,
          "message": "Mi nombre y apellido: Jose Pardo.",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "10/05/2022",
          "hour_message": "10:51 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 10:51:01",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7860,
          "message": "Email: Jp_ing23@yahoo.es",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "10/05/2022",
          "hour_message": "10:51 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 10:51:12",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7860,
          "message": "Celular: 976-355173",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "10/05/2022",
          "hour_message": "10:51 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 10:51:25",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7860,
          "message": "Proyecto de interes: We Live",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "10/05/2022",
          "hour_message": "10:51 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 10:51:37",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7860,
          "message": "N∞ Ambientes: 03 dormitorios",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "10/05/2022",
          "hour_message": "10:51 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 10:51:59",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7860,
          "message": "Y especialmente interesado en el Dpto. 106",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "10/05/2022",
          "hour_message": "10:52 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-10 10:52:18",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": "Jp_ing23@yahoo.es"
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": "Jose"
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": "976355173"
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": "Pardo"
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": "2"
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": "2"
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7849,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "PwfMqDUYAsBe4TWkUbEX",
      "date_created": "2022-05-06 15:22:21",
      "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7849,
          "message": "hola",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "06/05/2022",
          "hour_message": "14:39 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-06 14:39:06",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7849,
          "message": "quisiera informacion del depto",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "06/05/2022",
          "hour_message": "14:39 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-06 14:39:20",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7849,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "06/05/2022",
          "hour_message": "15:22 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-06 15:22:21",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7839,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "1PtsojXvdq99RVNqst1L",
      "date_created": "2022-05-05 17:11:09",
      "message": "hola",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7839,
          "message": "hola",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "05/05/2022",
          "hour_message": "16:55 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-05 16:55:58",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7839,
          "message": "hola",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "05/05/2022",
          "hour_message": "17:11 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-05 17:11:09",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7834,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "R81jbf914ifKif0bdC9g",
      "date_created": "2022-05-05 17:11:22",
      "message": "hola",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7834,
          "message": "Hola",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "05/05/2022",
          "hour_message": "14:41 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-05 14:41:54",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7834,
          "message": "Prueba",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "05/05/2022",
          "hour_message": "14:42 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-05 14:42:16",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7834,
          "message": "hola",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "05/05/2022",
          "hour_message": "17:11 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-05 17:11:22",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7697,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "AGhClPfHEaGOErqvz9JT",
      "date_created": "2022-05-02 11:15:35",
      "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7697,
          "message": "Buenas tardes",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "01/05/2022",
          "hour_message": "17:49 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-01 17:49:54",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7697,
          "message": "Departamentos con 3 dormitorios?",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "01/05/2022",
          "hour_message": "17:50 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-01 17:50:18",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7697,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "02/05/2022",
          "hour_message": "11:15 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-02 11:15:35",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7693,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "j3pWi7dyw5M8BW1zqRgK",
      "date_created": "2022-05-02 11:15:28",
      "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7693,
          "message": "Buenas quisiera informaciÛn de un departamento de 2 dormitorios",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "01/05/2022",
          "hour_message": "14:21 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-01 14:21:58",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7693,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "02/05/2022",
          "hour_message": "11:15 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-02 11:15:28",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7690,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "PkexOeCnqKnLzAAj28ac",
      "date_created": "2022-05-02 11:15:24",
      "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7690,
          "message": "Hola mi nombre es Gisell Paredes busco dpto de 1 habitacion con vista exterior",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "01/05/2022",
          "hour_message": "12:47 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-01 12:47:44",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7690,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "02/05/2022",
          "hour_message": "11:15 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-02 11:15:24",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7686,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "HGfgIZv97aGC29jvD0na",
      "date_created": "2022-05-02 11:15:19",
      "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7686,
          "message": "Hola, costo del departamento de 3 dormitorios",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "01/05/2022",
          "hour_message": "11:28 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-01 11:28:09",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7686,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "02/05/2022",
          "hour_message": "11:15 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-05-02 11:15:19",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7675,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "kAsXVSWQwGzcRoEfdAz1",
      "date_created": "2022-04-30 17:31:43",
      "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7675,
          "message": "Buenas tardes el precio del departamento  de 3 hab por favor",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "30/04/2022",
          "hour_message": "15:08 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-04-30 15:08:32",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7675,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "30/04/2022",
          "hour_message": "17:31 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-04-30 17:31:43",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7653,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "asY4qZC2v5txyP0BOAV9",
      "date_created": "2022-04-30 11:00:46",
      "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7653,
          "message": "Buenas dias quisiera saber, sobre la venta de departamentos",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "30/04/2022",
          "hour_message": "10:09 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-04-30 10:09:06",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7653,
          "message": "en Jesus maria o publo Libre",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "30/04/2022",
          "hour_message": "10:09 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-04-30 10:09:18",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7653,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "30/04/2022",
          "hour_message": "11:00 AM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-04-30 11:00:46",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    },
    {
      "id_chat": 7634,
      "name_chat": "Visitante Web",
      "photo_chat": null,
      "id_freshchat": "MJXZQ9Z19vPUhnaYynxB",
      "date_created": "2022-04-29 16:29:55",
      "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
      "resource": "WEB",
      "number_chat": "",
      "id_seller": 34,
      "status_chat": 1,
      "expired_whatsapp": 0,
      "send_message_react": 0,
      "chat_off": 0,
      "messages": [
        {
          "id_chat": 7634,
          "message": "Quiero saber informaciÛn sobre un departamento",
          "type": "TEXT",
          "from_user": 0,
          "date_message": "29/04/2022",
          "hour_message": "15:56 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-04-29 15:56:41",
          "seen": 1,
          "is_message_intern": 0,
          "name_contact": null
        },
        {
          "id_chat": 7634,
          "message": "Hola! ? °Bienvenido a San Charbel Edificaciones! ? Gracias por escribirnos. Esperamos que t˙ y tu familia se encuentren bien ?. Estoy para ayudarte en lo que necesites por este canal digital. ???? Por favor comp·rtenos los siguientes datos para brindarte mayor informaciÛn. ?? Nombre y Apellido: ?? Email: ?? Telefono: ?? Proyecto de interÈs: ?? N∞ de ambientes: °Muchas gracias! ?",
          "type": "TEXT",
          "from_user": 1,
          "date_message": "29/04/2022",
          "hour_message": "16:29 PM",
          "photo": null,
          "audio": null,
          "path_file": null,
          "date_created": "2022-04-29 16:29:55",
          "seen": 0,
          "is_message_intern": 0,
          "name_contact": null
        }
      ],
      "form": [
        {
          "id_form_det": 214,
          "id_form": 3,
          "type": "EMAIL",
          "max_length": 150,
          "field_name": "Email",
          "key_name": "email",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 215,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Nombre",
          "key_name": "name",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 216,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 20,
          "field_name": "DNI",
          "key_name": "dni",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 217,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Telefono",
          "key_name": "phone",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form_det": 218,
          "id_form": 3,
          "type": "TEXT",
          "max_length": 100,
          "field_name": "Apellido",
          "key_name": "lastname",
          "list_values": [],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "formAdd": [
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Proyecto",
          "key_name": "id_project",
          "list_values": [
            {
              "label": "Our Park",
              "value": 1
            },
            {
              "label": "We Live",
              "value": 2
            },
            {
              "label": "Join",
              "value": 3
            },
            {
              "label": "Up Living",
              "value": 4
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        },
        {
          "id_form": 3,
          "type": "SELECT",
          "max_length": null,
          "field_name": "Medio de CaptaciÛn",
          "key_name": "id_captation",
          "list_values": [
            {
              "label": "facebook",
              "value": 2
            },
            {
              "label": "feria expo urbania",
              "value": 14
            },
            {
              "label": "portal nexo ",
              "value": 17
            },
            {
              "label": "portal urbania",
              "value": 18
            },
            {
              "label": "portal a donde vivir ",
              "value": 19
            },
            {
              "label": "3 dÌas de locura inmobiliaria",
              "value": 21
            },
            {
              "label": "plan referido",
              "value": 27
            },
            {
              "label": "bancos",
              "value": 30
            },
            {
              "label": "publicidad en el proyecto / sala de ventas",
              "value": 31
            },
            {
              "label": "sala de ventas de otro proyecto",
              "value": 33
            },
            {
              "label": "volanteo",
              "value": 34
            },
            {
              "label": "instagram",
              "value": 36
            },
            {
              "label": "google adwords ",
              "value": 38
            },
            {
              "label": "org·nico ",
              "value": 40
            },
            {
              "label": "google display",
              "value": 43
            },
            {
              "label": "mailing",
              "value": 45
            },
            {
              "label": "mensaje de texo",
              "value": 46
            },
            {
              "label": "feria nexo inmobliario",
              "value": 47
            },
            {
              "label": "evento join enero 21",
              "value": 48
            },
            {
              "label": "org·nico",
              "value": 49
            },
            {
              "label": "feria expourbania online",
              "value": 50
            },
            {
              "label": "evento up living marzo 21",
              "value": 51
            },
            {
              "label": "mailingurb",
              "value": 52
            },
            {
              "label": "mailingadv",
              "value": 53
            },
            {
              "label": "whatsapp masivo",
              "value": 54
            },
            {
              "label": "evento join abril 21",
              "value": 55
            },
            {
              "label": "campaÒa de mÈdicos abril 21",
              "value": 56
            },
            {
              "label": "cierra puertas abril 21",
              "value": 57
            },
            {
              "label": "tiktok",
              "value": 58
            },
            {
              "label": "mybusiness",
              "value": 59
            },
            {
              "label": "llamadas convocatoria call center",
              "value": 60
            },
            {
              "label": "brochure",
              "value": 61
            },
            {
              "label": "feria nexo 2022",
              "value": 62
            },
            {
              "label": "qr_feria",
              "value": 63
            },
            {
              "label": "qr_cerco ",
              "value": 64
            }
          ],
          "enabled": 1,
          "order": "1",
          "required": 0,
          "value": ""
        }
      ],
      "seller": {
        "id_customers_contact": 34,
        "id_customers": 3,
        "name_contact": "Ra˙l Periche",
        "email_contact": "rperiche@sancharbel.pe",
        "phone_contact": "999999994",
        "enabled_contact": 1,
        "master_user": 0,
        "password_contact": "rperiche1234",
        "id_profile": 11,
        "id_job": 5,
        "has_seller": 0,
        "is_agent": 1,
        "name_short": "Ra˙l Periche",
        "notification_multiagente": 1
      }
    }
  ],
  "header": []
}

export default data
